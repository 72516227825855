<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Master Kas
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right" v-if="!showForm">
                <button type="button" class="btn btn-blue mb-1" @click="resetModel(), showForm = true">
                    Tambah Kas
                </button>
            </div>
        </div>
        <div class="row mt-1" v-if="showForm == true">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Nama Bank</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Bank" v-model="model.nama_bank">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">No. Rekening</label>
                    <input type="text" class="form-control" placeholder="Masukkan No. Rekening" v-model="model.no_rekening">
                </div>
            </div>
            <div class="col-lg-4">
                <label for="" class="f-14">Saldo Awal</label>
                <div class="form-group input-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Rp</div>
                        </div>
                        <vue-numeric class="form-control" separator="." v-model="model.saldo_awal" placeholder="Masukkan Saldo Awal"></vue-numeric>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 align-self-center text-right mb-1">
                <button type="button" class="btn text-danger" @click="showForm = false">Batal</button>
                <button type="button" class="btn btn-blue" @click="showForm = false, save()">Simpan</button>
            </div>
        </div>
        <div class="table-responsive" v-if="!showForm">
            <table class="table">
                <thead >
                    <tr>
                        <th style="background: #3D8D4629" class="text-left">Nama Bank</th>
                        <th style="background: #3D8D4629">No. Rekening</th>
                        <th style="background: #3D8D4629">Saldo Awal</th>
                        <th style="background: #3D8D4629">Saldo Masuk</th>
                        <th style="background: #3D8D4629">Saldo Keluar</th>
                        <th style="background: #3D8D4629">Sisa Saldo</th>
                        <th style="background: #3D8D4629">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="datas.length > 0">
                    <tr v-for="(data, index) in datas" :key="index">
                        <td>{{ data.nama_bank }}</td>
                        <td>{{ data.no_rekening }}</td>
                        <td>{{ data.saldo_awal | currency }}</td>
                        <td>{{ data.kas_masuk | currency }}</td>
                        <td>{{ data.kas_keluar | currency }}</td>
                        <td>{{ data.sisa_kas | currency }}</td>
                        <td>
                            <button class="btn btn-rounded btn-warning" title="Edit" @click="model = data, showForm = true"><i class="fa fa-edit"></i></button>&nbsp;
                            <button class="btn btn-rounded btn-danger" title="Hapus" @click="destroy(data)"><i class="fa fa-trash"></i></button>&nbsp;
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="4" class="text-center">Tidak ada data</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            showForm: false,
            datas: [],
            model: {
                id_project: this.$route.params.id_project,
                nama_bank: '',
                no_rekening: '',
                saldo_awal: ''
            }
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/project/keuangan/master_kas?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.datas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        save() {
            let _ = this
            if (_.model.id) {
                _.axios.put('/project/keuangan/master_kas/' + _.model.id, _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                _.axios.post('/project/keuangan/master_kas', _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Master Kas',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Master Kas <b>' + x.nama_bank + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/keuangan/master_kas/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Master Kas',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        resetModel(){
            this.model= {
                id_project: this.$route.params.id_project,
                nama_bank: '',
                no_rekening: '',
                saldo_awal: ''
            }
        }
    },
    mounted() {
        let _ = this
        _.get()
    }
}
</script>