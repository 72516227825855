<template>
    <section>
        <div class="col">
            <div class="row mb-1">
                <img src="/assets/img/icon/icon-company.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                <div class="col align-self-center">
                    <p class="font-600 f-20 mb-0">History Complain</p>
                </div>
                <div class="col-lg-6 col-xs-12 text-right p-0">
                </div>
            </div>
        </div>
        <a class="text-primary" @click="hideHistoryComplain()"><i class="fa fa-arrow-left"></i> Kembali</a>
        <select name="" id="" class="form-control mt-1 col-3" v-model="id_konstruksi">
            <option value="">Pilih Konstruksi</option>
            <option v-for="(konstruksi, index) in konstruksis" :key="index" :value="konstruksi.id">{{ konstruksi.name }}</option>
        </select>
        <div class="col mt-2" v-if="id_konstruksi">
            <div class="row mb-1 pb-1" v-for="complain in complains" :key="complain.id" style="border-bottom: 1px solid #F2F2F2;">
                <div class="col-4">
                    <p class="mb-0 f-14 font-600">{{ complain.konstruksi.name }} 
                        <span class="badge mt-1 float-right" :class="statusColor(complain.konstruksi.status)">
                            {{ complain.konstruksi.status }}
                        </span>
                    </p>
                    <div class="row">
                        <div class="progress mb-1 mt-1 align-self-center" style="width: 50%; height: 8px">
                            <div class="progress-bar" role="progressbar" :style="'width: '+complain.konstruksi.progress_bobot+'%;background: #3C8B43;'" :aria-valuenow="complain.konstruksi.progress_bobot" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span class="align-self-center ml-1">{{ complain.konstruksi.progress_bobot }}%</span>
                    </div>
                </div>
                <div class="col-5" style="border-left: 1px solid #F2F2F2; border-right: 1px solid #F2F2F2;">
                    <div class="row">
                        <div class="col">
                            <p class="font-600"> 
                                {{ complain.description }} 
                                <span class="badge badge-danger rounded">
                                    {{ complain.mood }}
                                </span>
                            </p>
                        </div>
                        <div class="col-4">
                            <select name="" id="" class="form-control" v-model="complain.status">
                                <option value="new">New</option>
                                <option value="on_going">On Going</option>
                                <option value="done">Done</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-3">
                    <img :src="file.url" alt="" v-for="file in complain.file_attachment" :key="file.id" @click="lihat(file.url)" 
                        class="rounded mr-1" style="width: 50px; height: 50px;">
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    props: ['konstruksis'],
    data() {
        return {
            id_konstruksi: '',
            complains: []
        }
    },
    methods: {
        hideHistoryComplain() {
            this.$root.$emit('showHistoryComplain', false)
        },
        getHistoryComplain() {
            let _ = this
            _.axios.get( 'project/konstruksi/complain?id_project=' + _.$route.params.id_project + '&id_konstruksi=' + _.id_konstruksi )
                .then( resp => {
                    this.complains = resp.data.data
                })
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 'Belum Mulai':
                    color = 'badge-light-secondary'
                    break;
                case 'Awas':
                    color = 'badge-light-danger'
                    break;
                case 'BAST':
                    color = 'badge-light-primary'
                    break;
                case 'Aman':
                    color = 'badge-light-success'
                    break;
                case 'Hati Hati':
                    color = 'badge-light-warning'
                    break;
            
                default:
                    break;
            }
            return color;
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
    },
    watch: {
        id_konstruksi: function() {
            this.getHistoryComplain()
        }
    }
}
</script>