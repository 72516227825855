<template>
    <div class="content-body">
        <div class="mb-2 text-primary">
            <!-- <router-link to="/"> -->
                <i class="material-icons">poll</i>
                <span class="menu-title align-self-center ml-1" style="vertical-align: super;"> Tambah Notifikasi</span>
            <!-- </router-link> -->
        </div>
        
        <div class="card w-100 ">
            <div class="card-content">
                <div class="card-body ic-statistic">
                    <div class="col mb-1">
                        <div class="row">
                            <img src="/assets/img/icon/icon-view-report.png" alt="" class="img-fluid mr-1" width="42">
                            <p class="f-20 mb-0 align-self-center">Kirim Notifikasi</p>
                        </div>
                    </div>
                    <hr>
                    <ValidationObserver ref="observer">
                        <div class="row">
                            <div class="col">
                                <ValidationProvider name="to" rules="required" v-slot="{ errors }">
                                    <div class="form-group col" :class="{ 'validation-warning': errors[0] }">
                                        <div class="row">
                                            <div class="col">
                                                <div class="row">
                                                    <input type="radio" name="" id="" value="0" v-model="model.to" class="align-self-center mr-1">
                                                    <label for="" class="f-14">Kirim Ke Semua</label>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <input type="radio" name="" id="" value="1" v-model="model.to" class="align-self-center mr-1">
                                                    <label for="" class="f-14">Kirim Ke Beberapa</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Recipient" rules="required" v-if="model.to == '1'">
                                    <div class="form-group">
                                        <label class="form-label">Kepada</label>
                                        <multiselect 
                                            v-model="selectedRecipients" 
                                            id="id" 
                                            track-by="id" 
                                            placeholder="Pilih penerima" 
                                            :options="staffs" 
                                            :multiple="true" 
                                            :custom-label="customLabel"
                                            :close-on-select="false"
                                            :loading="isLoading"/>
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Judul" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label class="">Judul</label>
                                        <input type="text" id="name" class="form-control" required v-model="model.title" placeholder="Masukkan Judul">
                                    </div>
                                </ValidationProvider>
                                <ValidationProvider name="Deskripsi" rules="required" v-slot="{ errors }">
                                    <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                        <label class="">Deskripsi</label>
                                        <textarea name="" id="" cols="30" rows="5" class="form-control" v-model="model.description" placeholder="Masukkan Deskripsi"></textarea>
                                    </div>
                                </ValidationProvider>
                            </div>
                            <div class="col">
                                <label for="">Foto</label>
                                <div style="text-align: -webkit-center;">
                                    <div v-if="temp_photo != null">
                                        <img :src="temp_photo" class="img-fluid" alt=""><br>
                                        <button class="btn btn-danger mt-1 mb-1" @click="temp_photo = null, model.photo = null">Hapus Foto</button>
                                    </div>
                                    <div v-else 
                                        style="
                                            text-align: center; 
                                            border-radius: 10px; 
                                            background: #E5E7E9;
                                            padding: 30px;
                                            cursor: pointer;
                                        ">
                                        <label for="file-upload" class="btn-edit" style="font-size: 40px; cursor: pointer;">
                                            <i class="fa fa-camera" aria-hidden="true"></i> 
                                            <p style="font-size: 14px">Pilih Foto</p>
                                        </label>
                                        <input id="file-upload" class="input-file-avatar d-none" type="file" v-on:change="Addphoto">
                                    </div>                                                        
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="text-right">
                            <button type="button" class="btn text-primary" @click="$router.back()">Batal</button>
                            <button type="submit" class="btn btn-primary float-right" @click="save()" :disabled="isLoading">
                                <div v-if="isLoading">Menyimpan <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Simpan</div>
                            </button>
                        </div>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            model: {
                to: '0',
                recipients: []
            },
            temp_photo: null,
            staffs: [],
            isLoading: false,
            selectedRecipients: []
        }
    },
    methods: {
        Addphoto (ev) {             
            const file          = ev.target.files[0];
            this.model.image    = ev.target.files[0]; 
            this.temp_photo     = URL.createObjectURL(file);     
        },
        save() {
            let _ = this

            _.isLoading = true

            _.selectedRecipients.forEach(elm => {
                _.model.recipients.push(elm.id_user)
            });

            let FData =  new FormData()
            for (let i in _.model){
                if (_.model[i] != null){
                    FData.append(i, _.model[i])
                }
            }

            _.axios.post('/notification', FData)
                .then(resp => {
                    _.$router.push('/notifikasi')
                    _.$notify({
                        type: 'success',
                        title: 'Success!',
                        text: resp.data.message
                    })
                })
                .catch(err => {
                    _.$notify({
                        type: 'error',
                        title: 'Error!',
                        text: err.response.data.errors
                    })
                    _.isLoading = false
                })
        },
        getRecipients() {
            let _ = this

            let target = ''
            if (localStorage.role == 'dsi') {
                target = 'company'
            } else if (localStorage.role == 'company') {
                target = 'project_manager'
            } else if (localStorage.role == 'pm') {
                target = 'staff_admin'
            }
            
            _.axios.get('/' + target)
                .then(resp => {
                    _.staffs = resp.data.data
                })
        },
        customLabel( {user} ) {
            return user.name;
        }
    },
    mounted() {
        this.getRecipients()
    }
}
</script>