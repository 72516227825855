<template>
    <div>
        <div class="image-promit"></div>
        <div style="position: absolute; top: 50px; width: 100%;" class="d-none d-lg-block">
            <p class="text-center"><img src="/assets/img/logo-login.png" alt=""></p>
            <p class="text-center text-white" style="font-size: 42px;"><b>Welcome to</b> Pro<b>MIT</b></p>
            <p class="text-center text-white" style="font-size: 26px;">Start manage your projects</p>
        </div>

        <div style="position: absolute; top: 20px; width: 100%;" class="d-lg-none">
            <p class="text-center"><img src="/assets/img/logo-login.png" alt=""></p>
            <p class="text-center text-white" style="font-size: 30px;"><b>Welcome to</b> Pro<b>MIT</b></p>
            <p class="text-center text-white" style="font-size: 16px;">Start manage your projects</p>
        </div>
        <ValidationObserver ref="observer">
            <div class="login-form pt-3 pb-2">
                <p class="text-center" style="font-size: 42px;"><b>Login</b></p>
                <form @submit.prevent="login()" class="mt-3">
                    <div class="alert-danger p-1 mb-1 text-center" style="border: 1px solid #FF9D9D; border-radius: 4px;" v-if="authFalse != 0">
                        <div v-for="(v, k) in errors" :key="k">
                            <p v-for="error in v" :key="error" class="mb-0 text-danger" v-html="error"></p>
                        </div>
                    </div>
                    <ValidationProvider name="email" rules="required|email" v-slot="{ errors }" ref="email">
                        <div class="col p-0">
                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                <label class="font-bold f-18">Email</label>
                                <input type="text" id="name" class="form-control" required v-model="email" placeholder="Masukkan Email" style="height: 48px;">
                                <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                            </div>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="password" rules="required" v-slot="{ errors }" ref="password">
                        <div class="col p-0">
                            <label class="font-bold f-18">Password</label>
                            <div class="form-group input-group mb-0" :class="{ 'validation-warning': errors[0] }">
                                <input :type="showPassword ? 'text' : 'password'" id="password" class="form-control border-right-0" v-model="password" placeholder="Masukkan Password" style="height: 48px;">
                                <span class="input-group-append">
                                    <div class="input-group-text pl-1 pr-1">
                                        <a href="javascript:;" @click="showPassword = !showPassword" class="text-blue f-16">
                                            <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
                                        </a>
                                    </div>
                                </span>
                            </div>
                            <small class="text-danger" v-if="errors[0]">{{ errors[0] }}</small>
                        </div>
                    </ValidationProvider>
                    <button type="submit" class="btn btn-primary waves-effect waves-light w-100 mt-2" :disabled="isLoading">
                        <div v-if="isLoading">Masuk <i class="fa fa-spinner fa-spin"></i></div>
                        <div v-else>Masuk</div>
                    </button>

                    <p class="font-600 mt-2 text-center">Belum punya akun?</p>
                    <p class="font-600 mb-0">
                        <span class="text-primary pointer" @click="$router.push('/register/company')">Daftar Sebagai Perusahaan</span><br>
                        <span class="text-primary pointer" @click="$router.push('/register/pm')">Daftar Sebagai Project Manager</span>
                    </p>
                </form>                
            </div>            
        </ValidationObserver>  
        <div style="position: absolute; bottom: 0px; width: 100%; text-align: center;">
            Copyright ©2021 Dana Syariah Indonesia. All Rights Reserved Copyright
        </div>
    </div> 
</template>
<script>
export default {
	name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            authFalse: 0,
            isLoading: false,
            errors: [],
            showPassword: false
        }
    },
	methods: {
		async login() {
            let _ = this  
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.isLoading = true
                _.axios.post('/auth/login', { email: _.email, password: _.password })
                    .then(resp=>{
                        _.$store.dispatch('login', resp.data.data)
                        if (resp.data.data.user.role == 'dsi') {
                            window.location = '/'
                        } else if (resp.data.data.user.role == 'company') {
                            window.location = '/company/view-report'
                        } else if (resp.data.data.user.role == 'pm') {
                            window.location = '/project'
                        } else {
                            _.errors = { 'account': ['Access Prohibited'] }
                            _.authFalse = 1
                            _.isLoading = false
                        }
                    })
                    .catch(err=>{
                        _.isLoading = false

                        if (err.response.data.message.password) {
                            this.$refs.password.applyResult({
                                errors: [err.response.data.message.password[0]],
                                valid: false,
                                failedRules: {}
                            });
                        }

                        if (err.response.data.message.email) {
                            this.$refs.email.applyResult({
                                errors: [err.response.data.message.email[0]],
                                valid: false,
                                failedRules: {}
                            });
                        }

                        if (err.response.data.message.account) {
                            this.$refs.email.applyResult({
                                errors: [err.response.data.message.account[0]],
                                valid: false,
                                failedRules: {}
                            });
                        }
                    })
            }
		}
	},
    created() {
        localStorage.removeItem('token')
    }
}
</script>