<template>
    <section>
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Buku Besar Konsumen
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right">
                <button type="button" class="btn btn-sm btn-warning" @click="get()" title="Refresh">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
        <div class="table-responsive mt-1">
            <table class="table">
                <thead >
                    <tr>
                        <th style="background: #3D8D4629">No.</th>
                        <th style="background: #3D8D4629">Nama Konsumen</th>
                        <th style="background: #3D8D4629">Nama Kavling</th>
                        <th style="background: #3D8D4629">Luas Tanah</th>
                        <th style="background: #3D8D4629">Tipe</th>
                        <th style="background: #3D8D4629">Harga Dasar</th>
                        <th style="background: #3D8D4629">Penambahan Harga</th>
                        <th style="background: #3D8D4629">Harga Final</th>
                        <th style="background: #3D8D4629">Status Prospek</th>
                        <th style="background: #3D8D4629">Status Proses</th>
                    </tr>
                </thead>
                <tbody v-if="datas.length > 0">
                    <tr v-for="(data, index) in datas" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.konstruksi.name }}</td>
                        <td>{{ data.luas_tanah }}m<sup>2</sup></td>
                        <td>{{ data.tipe }}</td>
                        <td>{{ data.konstruksi.price | currency }}</td>
                        <td>{{ 0 | currency }}</td>
                        <td>{{ data.final_price | currency }}</td>
                        <td><button class="btn btn-sm " :class="status_prospek(data.status_prospek)">{{ data.status_prospek.toUpperCase() }}</button></td>
                        <td><button class="btn btn-sm " :class="status_proses(data.status_proses)">{{ data.status_proses.toUpperCase() }}</button></td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="10">Tidak ada data</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            datas: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/penjualan?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.datas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        status_prospek(x) {
            let color = ''
            switch (x) {
                case 'prospek':
                    color = 'btn-success'
                    break;
                case 'hot':
                    color = 'btn-success'
                    break;
                case 'extra_hot':
                    color = 'btn-success'
                    break;
                case 'tidak_prospek':
                    color = 'btn-success'
                    break;
            
                default:
                    color = '';
                    break;
            }
            return color;
        },
        status_proses(x) {
            let color = ''
            switch (x) {
                case 'leads':
                    color = 'btn-blue'
                    break;
                case 'survey':
                    color = 'btn-warning'
                    break;
                case 'booking':
                    color = 'btn-info'
                    break;
                case 'akad':
                    color = 'btn-success'
                    break;
                case 'batal_akad': 
                    color = 'btn-danger'
                    break;
            
                default:
                    color = '';
                    break;
            }
            return color;
        }
    },
    mounted() {
        let _ = this
        _.get()
    }
}
</script>