<template>
    <svg width="50" height="50" class="chart-container">
        <circle
            cx="25"
            cy="25"
            r="22"
            :fill="(progress/100) < 1 ? 'rgba(255, 157, 43, 0.18)' : 'rgba(126, 184, 131, 0.2)'"
        />
        <circle
            cx="25"
            cy="25"
            r="22"
            class="front"
            :stroke="(progress/100) < 1 ? '#FF9D2B' : '#7EB883'"
            :stroke-dasharray="2 * 3.14 * 22 * (progress/100)"
        />
        <g class="text">
            <text
                x="25"
                y="25"
                alignment-baseline="central"
                text-anchor="middle"
                id="percentage"
                :fill="(progress/100) < 1 ? '#FF9D2B' : '#7EB883'"
            >
            {{(progress/100) * 100}}%
            </text>
        </g>
    </svg>
</template>
<script>
export default {
    props: ['progress']
}
</script>