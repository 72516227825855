<template>
    <section>
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Jurnal
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right">
                <button type="button" class="btn btn-sm btn-warning" @click="get()" title="Refresh">
                    <i class="fa fa-refresh"></i>
                </button>
            </div>
        </div>
        <div class="table-responsive mt-1">
            <table class="table">
                <thead >
                    <tr>
                        <th style="background: #3D8D4629">No.</th>
                        <th style="background: #3D8D4629">No. Jurnal</th>
                        <th style="background: #3D8D4629">Transaksi</th>
                        <th style="background: #3D8D4629">Konstruksi</th>
                        <th style="background: #3D8D4629">Kas</th>
                        <th style="background: #3D8D4629">Pemilik Lahan</th>
                        <th style="background: #3D8D4629">Investor</th>
                        <th style="background: #3D8D4629">Nominal</th>
                        <th style="background: #3D8D4629">Keterangan</th>
                        <th style="background: #3D8D4629">Attachment</th>
                        <th style="background: #3D8D4629">Status</th>
                        <th style="background: #3D8D4629">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="datas.length > 0">
                    <tr v-for="(data, index) in datas" :key="index">
                        <td class="text-center">{{index+1}}</td>
                        <td>{{ data.no_jurnal }}</td>
                        <td class="text-left">{{ data.col_transaksi_name }}</td>
                        <td class="text-left">{{ data.col_konstruksi_name }}</td>
                        <td class="text-left">{{ data.col_kas_name }}</td>
                        <td class="text-left">{{ data.col_pemilik_lahan_name }}</td>
                        <td class="text-left">{{ data.col_investor_name }}</td>
                        <td>{{ data.nominal | currency }}</td>
                        <td class="text-left">{{ data.keterangan ? data.keterangan : '-' }}</td>
                        <td>
                            <button v-if="data.file_attachment" title="Download Attachment" class="btn btn-blue btn-sm">
                                <a :href="data.file_attachment ? data.file_attachment : '#'" target="blank_" class="text-white">
                                    <i class="fa fa-download"></i>
                                </a>
                            </button>
                            <span v-else>-</span>
                        </td>
                        <td>
                            <div :class="data.status_approval == 'approved' ? 'text-success' : data.status_approval == 'waiting' ? 'text-warning' : 'text-danger'">
                                {{ data.status_approval.toUpperCase() }}
                            </div>
                        </td>
                        <td class="text-center">
                            <div class="btn-action-block">
                                <button type="button" class="btn btn-sm p-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="material-icons">more_horiz</span>
                                </button>
                                <div class="dropdown-menu mr-3">
                                    <a class="dropdown-item text-black" @click="approval(data, 'approved')" v-if="data.status_approval == 'waiting'">Approve</a>
                                    <a class="dropdown-item text-black" @click="approval(data, 'rejected')" v-if="data.status_approval == 'waiting'">Reject</a>
                                    <div class="dropdown-divider" v-if="data.status_approval == 'waiting'"></div>
                                    <a class="dropdown-item text-black" @click="destroy(data)">Delete</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="12">Tidak ada data</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            datas: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/project/jurnal?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.datas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        status_approval(x) {
            let color = ''
            switch (x) {
                case 'approved':
                    color = 'btn-success'
                    break;
                case 'rejected':
                    color = 'btn-danger'
                    break;
            
                default:
                    color = '';
                    break;
            }
            return color;
        },
        approval(x, status) {
            let _ = this
            let data = {
                status_approval: status 
            };
            _.axios.post('/project/jurnal/approval/' + x.id, data)
                .then(resp => {
                    console.log(resp.data)
                    _.get()
                })
        },
        destroy(x){
            let _ = this

            _.$swal.fire({
                title: 'Hapus Jurnal',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Jurnal <b>' + x.no_jurnal + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/jurnal/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Jurnal',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        }
    },
    mounted() {
        let _ = this
        _.get()
    }
}
</script>