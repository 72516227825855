<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Buku Besar Investor
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right" v-if="!showForm">
                <button type="button" class="btn btn-blue mb-1" @click="showForm = true">
                    Tambah Investor
                </button>
            </div>
        </div>
        <div class="row mt-1" v-if="showForm == true">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Nama Investor</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Investor" v-model="model.name">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Tenor (Bulan)</label>
                    <input type="text" class="form-control" placeholder="Masukkan Tenor (Bulan)" v-model="model.tenor">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Tgl. Jatuh Tempo</label>
                    <date-picker
                        v-model="model.tgl_jatuh_tempo"
                        format="DD/MM/YYYY"
                        type="date"
                        :input-class="'form-control'"
                        value-type="YYYY-MM-DD"
                        placeholder="Masukkan Tgl. Jatuh Tempo"
                        style="width: 100%;">
                    </date-picker>
                </div>
            </div>
            <div class="col-lg-12 align-self-center text-right mb-1">
                <button type="button" class="btn text-danger" @click="showForm = false">Batal</button>
                <button type="button" class="btn btn-blue" @click="showForm = false,save()">Simpan</button>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table">
                <thead >
                    <tr>
                        <th style="background: #3D8D4629" class="text-left">Nama Investor</th>
                        <th style="background: #3D8D4629">Tenor (Bulan)</th>
                        <th style="background: #3D8D4629">Tgl. Jatuh Tempo</th>
                        <th style="background: #3D8D4629">Nilai Pendanaan Awal (Rp)</th>
                        <th style="background: #3D8D4629">Pengembalian Pokok (Rp)</th>
                        <th style="background: #3D8D4629">Sisa Utang Pokok (Rp)</th>
                        <th style="background: #3D8D4629">Beban Bunga (Rp)</th>
                        <th style="background: #3D8D4629">Bunga Dibayarkan (Rp)</th>
                        <th style="background: #3D8D4629">Sisa Beban Bunga (Rp)</th>
                        <th style="background: #3D8D4629">Total Kewajiban Awal (Rp)</th>
                        <th style="background: #3D8D4629">Kewajiban Sudah Dibayar (Rp)</th>
                        <th style="background: #3D8D4629">Sisa Kewajiban Belum Dibayar (Rp)</th>
                        <th style="background: #3D8D4629">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="datas.length > 0">
                    <tr v-for="(data, index) in datas" :key="index">
                        <td>{{ data.name }}</td>
                        <td>{{ data.tenor }}</td>
                        <td>{{ data.tgl_jatuh_tempo | moment("DD/MM/YYYY") }}</td>
                        <td>{{ data.nilai_pendanaan_awal | currency }}</td>
                        <td>{{ data.pengembalian_pokok | currency }}</td>
                        <td>{{ data.sisa_utang_pokok | currency }}</td>
                        <td>{{ data.beban_bunga | currency }}</td>
                        <td>{{ data.bunga_dibayarkan | currency }}</td>
                        <td>{{ data.sisa_beban_bunga | currency }}</td>
                        <td>{{ data.total_kewajiban_awal | currency }}</td>
                        <td>{{ data.kewajiban_sudah_dibayar | currency }}</td>
                        <td>{{ data.sisa_kewajiban_belum_dibayar | currency }}</td>
                        <td>
                            <button type="button" class="btn btn-warning btn-rounded" title="Edit" data-toggle="modal" data-target="#edit_investor"
                                @click="model_edit = data">
                                <i class="fa fa-edit"></i>
                            </button>&nbsp;
                            <button class="btn btn-rounded btn-danger" title="Hapus" @click="destroy(data)"><i class="fa fa-trash"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="13" class="text-center">Tidak ada data</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="modal fade text-left show" id="edit_investor" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header modal-primary white">
                        <h5 class="modal-title" id="myModalLabel160">Edit Investor</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row mt-1">
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="">Nama Investor</label>
                                    <input type="text" class="form-control" placeholder="Masukkan Nama Investor" v-model="model_edit.name">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="">Tenor (Bulan)</label>
                                    <input type="text" class="form-control" placeholder="Masukkan Tenor (Bulan)" v-model="model_edit.tenor">
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="form-group">
                                    <label for="">Tgl. Jatuh Tempo</label>
                                    <date-picker
                                        v-model="model_edit.tgl_jatuh_tempo"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        :input-class="'form-control'"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Masukkan Tgl. Jatuh Tempo"
                                        style="width: 100%;">
                                    </date-picker>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-primary waves-effect waves-light" data-dismiss="modal">Batal</button>
                        <button type="button" class="btn btn-primary waves-effect waves-light" data-dismiss="modal" @click="edit()">Simpan</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            showForm: false,
            datas: [],
            model: {
                id_project: this.$route.params.id_project,
                name: '',
                tenor: '',
                tgl_jatuh_tempo: '',
                nilai_pendanaan_awal: '',
                pengembalian_pokok: '',
                beban_bunga: '',
                bunga_dibayarkan: '',
            },
            model_edit: {
                id: '',
                name: '',
                tenor: '',
                tgl_jatuh_tempo: '',
                nilai_pendanaan_awal: '',
                pengembalian_pokok: '',
                beban_bunga: '',
                bunga_dibayarkan: '',
            }
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/project/keuangan/investor?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.datas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        save() {
            let _ = this
            _.axios.post('/project/keuangan/investor', _.model)
                .then(resp => {
                    console.log(resp.data)
                    _.get()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        edit() {
            let _ = this
            _.axios.put('/project/keuangan/investor/' + _.model_edit.id, _.model_edit)
                .then(resp => {
                    console.log(resp.data)
                    _.get()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Investor',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Investor <b>' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/keuangan/investor/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Investor',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    mounted() {
        let _ = this
        _.get()
    }
}
</script>