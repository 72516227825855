<template>
    <section>
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="form-group input-group" v-if="!showAddPerizinan">
                    <span class="input-group-prepend">
                        <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                            <i class="fa fa-search"></i>
                        </div>
                    </span> 
                    <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search...">
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right">
                <button type="button" class="btn btn-blue" v-if="showAddPerizinan == false" v-can="'pm'"
                    @click="
                        showAddPerizinan = true,
                        perizinan_addPIC(),
                        perizinan_addSyarat()
                    ">
                    Tambah Perizinan
                </button>
            </div>
        </div>
        <div v-if="showAddPerizinan">
            <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;">
                <div class="card-content box-shadow">
                    <div class="card-body pt-1 pb-1">
                        <div class="col">
                            <div class="row">
                                <img src="/assets/images/perizinan.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                                <div class="col text-left f-12">
                                    <input type="text" placeholder="Masukkan Nama Dokumen" class="border-0 w-50 f-16 font-600" v-model="model_perizinan.name"><br>
                                    Waktu:
                                    <date-picker
                                        v-model="date_range_perizinan"
                                        format="DD/MM/YYYY"
                                        type="date"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Masukkan Waktu"
                                        range
                                        input-class="border-0 f-12 text-primary font-600 w-100"
                                        style="width: 180px;"
                                        @input="
                                            model_perizinan.start_date = date_range_perizinan[0]
                                            model_perizinan.end_date   = date_range_perizinan[1]">
                                    </date-picker>
                                    <!-- Waktu: <vue-numeric class="border-0 f-12 text-primary font-600" separator="." v-model="model_perizinan.waktu" placeholder="Masukkan Waktu" style="width: 50px;"></vue-numeric> -->
                                    Biaya: <vue-numeric class="border-0 f-12 text-primary font-600" @input="getTotalPrice" separator="." currency="Rp" v-model="model_perizinan.biaya" placeholder="Masukkan Biaya"></vue-numeric>
                                    <b>Total Biaya:</b> <vue-numeric class="bg-white border-0 f-12 text-primary font-600" separator="." currency="Rp" v-model="model_perizinan.total_biaya" placeholder="0" disabled></vue-numeric>
                                </div>
                                <div class="col-4">
                                    <label for="">Dependencies</label>
                                    <multiselect 
                                        v-model="dependencies"
                                        label="name"
                                        track-by="id" 
                                        placeholder="Pilih Perizinan (Optional)"
                                        :options="project.perizinans"
                                        :multiple="true" 
                                        :searchable="true"  
                                        :close-on-select="false">
                                        <span slot="noResult">Oops! Data not found.</span>
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col p-0">
                            <hr>
                            <p class="f-14 font-600">PIC 
                                <button type="button" class="btn p-0" title="Tambah PIC" @click="perizinan_addPIC()">
                                    <i class="fa fa-plus-circle f-14 ml-1 pointer text-blue"></i>
                                </button>
                            </p>
                            <div class="col-lg-4 col-xs-12 p-0" v-for="(data, index) in model_perizinan.pics" :key="index">
                                <hr>
                                <div class="form-group mb-1">
                                    <label for="" class="font-600 f-12">Nama</label>
                                    <vue-autosuggest
                                        v-model					= data.name
                                        :suggestions			= suggestPIC
                                        :get-suggestion-value	= suggestLabel
                                        :input-props			="{class: 'border-0 pl-0 pt-0 pb-0', placeholder:'Masukkan Nama'}"
                                        @input					= searchPIC
                                        @selected				= selectedPICPerizinan
                                        @click="getIdPICPerizinan(index)">
                                        <template slot-scope="{suggestion}">
                                            <span class="my-suggestion-item m-0 align-self-center">Nama: <b>{{suggestion.item.name}}</b></span>
                                            <div class="row">
                                                <span class="my-suggestion-item col m-0 align-self-center">No. Telepon: <b>{{suggestion.item.phone_number}}</b></span>
                                                <span class="my-suggestion-item col m-0 align-self-center">Jabatan: <b>{{suggestion.item.position}}</b></span>
                                            </div>								
                                        </template>
                                    </vue-autosuggest>
                                </div>
                                <div class="col">
                                    <div class="row">
                                        <div class="form-group mb-0">
                                            <label for="" class="font-600 f-12">No. Telepon</label>
                                            <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="data.no_telp" placeholder="Masukkan No. Telepon" required>
                                        </div>
                                        <div class="form-group mb-0">
                                            <label for="" class="font-600 f-12">Jabatan</label>
                                            <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="data.jabatan" placeholder="Masukkan Jabatan" required>
                                        </div>
                                    </div>
                                </div>
                                <button type="button" class="btn p-0" title="Hapus PIC" style="position: absolute; right: 0px; top: 30%;" 
                                    @click="perizinan_deletePIC(index)" v-if="model_perizinan.pics.length > 1">
                                    <i class="fa fa-trash f-20 text-danger m-1 pointer"></i>
                                </button>
                            </div>
                            <div id="accordionSyarat" class="mt-1">
                                <div class="col pt-1 pl-0 pr-0" style="border-top: 1px solid rgba(34, 41, 47, 0.1);" v-for="(syarat, index_syarat) in model_perizinan.syarats" :key="index_syarat">
                                    <div class="row mb-1">
                                        <img src="/assets/images/sub-syarat.png" alt="" class="img-fluid ml-1" style="width: 24px; height: 24px;">
                                        <div class="col align-self-center text-left f-12">
                                            <input type="text" :placeholder="'Masukkan Syarat ' + ( index_syarat+1 == 1 ? 'Pertama' : 'Ke' + terter(parseInt(index_syarat+1)).toLowerCase())" class="border-0 w-50 f-16 font-600" v-model="syarat.name"><br>
                                            Waktu: 
                                            <date-picker
                                                v-model="date_range_syarat[index_syarat]"
                                                format="DD/MM/YYYY"
                                                type="date"
                                                value-type="YYYY-MM-DD"
                                                placeholder="Masukkan Waktu"
                                                range
                                                input-class="border-0 f-12 text-primary font-600 w-100"
                                                style="width: 180px;"
                                                @input="
                                                    syarat.start_date = date_range_syarat[index_syarat][0]
                                                    syarat.end_date   = date_range_syarat[index_syarat][1]">
                                            </date-picker>
                                            Biaya: <vue-numeric class="border-0 f-12 text-primary font-600" separator="." @input="getTotalPrice" currency="Rp" v-model="syarat.biaya" placeholder="Masukkan Biaya"></vue-numeric>
                                            <hr>
                                            <p class="f-14 font-600">PIC 
                                                <button type="button" class="btn p-0" title="Tambah PIC" @click="perizinan_syarat_add_pic(index_syarat)">
                                                    <i class="fa fa-plus-circle f-14 ml-1 pointer text-blue"></i>
                                                </button>
                                            </p>
                                            <div class="col col-xs-12 p-0" v-for="(data, index) in syarat.pics" :key="index">
                                                <hr>
                                                <div class="form-group mb-1">
                                                    <label for="" class="font-600 f-12">Nama</label>
                                                    <!-- <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="data.name" placeholder="Masukkan Nama" required> -->
                                                    <vue-autosuggest
                                                        v-model					= data.name
                                                        :suggestions			= suggestPIC
                                                        :get-suggestion-value	= suggestLabel
                                                        :input-props			="{class: 'border-0 pl-0 pt-0 pb-0', placeholder:'Masukkan Nama'}"
                                                        @input					= searchPIC
                                                        @selected				= selectedPICSyarat
                                                        @click="getIdPICSyarat(index_syarat, index)">
                                                        <template slot-scope="{suggestion}">
                                                            <span class="my-suggestion-item m-0 align-self-center">Nama: <b>{{suggestion.item.name}}</b></span>
                                                            <div class="row">
                                                                <span class="my-suggestion-item col m-0 align-self-center">No. Telepon: <b>{{suggestion.item.phone_number}}</b></span>
                                                                <span class="my-suggestion-item col m-0 align-self-center">Jabatan: <b>{{suggestion.item.position}}</b></span>
                                                            </div>								
                                                        </template>
                                                    </vue-autosuggest>
                                                </div>
                                                <div class="col">
                                                    <div class="row">
                                                        <div class="form-group mb-0">
                                                            <label for="" class="font-600 f-12">No. Telepon</label>
                                                            <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="data.no_telp" placeholder="Masukkan No. Telepon" required>
                                                        </div>
                                                        <div class="form-group mb-0">
                                                            <label for="" class="font-600 f-12">Jabatan</label>
                                                            <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="data.jabatan" placeholder="Masukkan Jabatan" required>
                                                        </div>
                                                    </div>
                                                </div>
                                                <button type="button" class="btn p-0" title="Hapus PIC" style="position: absolute; right: 0px; top: 30%;" 
                                                    @click="perizinan_syarat_remove_pic(index_syarat, index)" v-if="syarat.pics.length > 1">
                                                    <i class="fa fa-trash f-20 text-danger m-1 pointer"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="col text-right">
                                            <button type="button" class="btn p-0" title="Tambah Syarat" @click="perizinan_addSyarat()" v-if="index_syarat == model_perizinan.syarats.length-1">
                                                <i class="fa fa-plus-circle f-20 m-1 pointer text-blue"></i>
                                            </button>
                                            <button type="button" class="btn p-0" title="Hapus Syarat" 
                                                @click="perizinan_deleteSyarat(index_syarat)" v-if="model_perizinan.syarats.length > 1">
                                                <i class="fa fa-trash f-20 text-danger m-1 pointer"></i>
                                            </button>
                                            <button type="button" class="btn p-0" title="Tambah Sub Syarat" data-toggle="collapse" :data-target="'#collapseSyarat_'+index_syarat"
                                                @click="perizinan_addSubSyarat(index_syarat)" v-if="syarat.subs.length == 0">
                                                <i class="fa fa-plus f-20 m-1 pointer text-primary"></i>
                                            </button>
                                        </div>
                                        <div>
                                            <button v-if="syarat.subs.length > 0" data-toggle="collapse" :data-target="'#collapseSyarat_'+index_syarat" aria-expanded="false" class="btn mr-1 pr-1 pl-0">
                                                <i class="f-20 fa fa-chevron-down"></i>
                                            </button>
                                        </div>
                                    </div>
                                    <div :id="'collapseSyarat_'+index_syarat" class="collapse" data-parent="#accordionSyarat">
                                        <div class="col pl-4 pr-0 bg-light" v-for="(sub_syarat, index_sub_syarat) in syarat.subs" :key="index_sub_syarat">
                                            <div class="row">
                                                <!-- <i class="fa fa-circle f-10 text-primary align-self-center ml-1"></i> -->
                                                <img src="/assets/images/sub-syarat.png" alt="" class="img-fluid ml-1 align-self-center" style="width: 24px; height: 24px;">
                                                <div class="col align-self-center">
                                                    <input type="text" :placeholder="'Masukkan Sub Syarat ' + ( index_sub_syarat+1 == 1 ? 'Pertama' : 'Ke' + terter(parseInt(index_sub_syarat+1)).toLowerCase())" class="border-0 w-50 f-14 text-black bg-light" required v-model="sub_syarat.name">
                                                </div>
                                                <div class="col text-right pr-0">
                                                    <button type="button" class="btn p-0" title="Tambah Sub Syarat" @click="perizinan_addSubSyarat(index_syarat)" v-if="index_sub_syarat == syarat.subs.length-1">
                                                        <i class="fa fa-plus-circle f-20 m-1 pointer text-blue"></i>
                                                    </button>
                                                    <button type="button" class="btn p-0 mr-1" title="Hapus Sub Syarat" @click="perizinan_deleteSubSyarat(index_syarat, index_sub_syarat)">
                                                        <i class="fa fa-trash f-20 text-danger m-1 pointer"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="text-right">
                                <button class="btn text-danger" @click="perizinan_reset()">Cancel</button>
                                <button class="btn btn-blue" @click="perizinan_submit()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="max-height:500px;display:inherit;overflow:auto !important;" class="mb-1" v-if="!showAddPerizinan">
            <div class="wrapper-gantt-chart">
                <div class="project-perizinan-wrapper">
                    <div class="wrapper-perizinan">
                        <div class="wrapper-jenis">
                            <h2>Jenis Perizinan</h2>
                        </div>
                        <div class="wrapper-detail-perizinan" v-for="perizinan in project.perizinans" :key="perizinan.id">
                            <div class="wrapper-title">
                                <p>{{ perizinan.name }}</p>
                            </div>
                            <p class="f-12 mb-0">
                                <span class="text-black">Waktu: </span><span class="text-primary mr-1 font-600">{{ perizinan.start_date | moment('D MMM YYYY') }} - {{ perizinan.end_date | moment('D MMM YYYY') }}</span>
                                <span class="d-lg-none"><br></span>
                                <span class="text-black">Biaya: </span><span class="text-primary mr-1 font-600">{{ perizinan.total_price | currency }}</span>
                                <span class="text-black">Total Biaya: </span><span class="text-primary mr-1 font-600">{{ perizinan.total_biaya_keseluruhan | currency }}</span>
                            </p>
                            <div class="row">
                                <div class="progress mb-0 ml-1 align-self-center" style="width: 50%; height: 8px">
                                    <div class="progress-bar" role="progressbar" :style="'width: '+perizinan.total_progress+'%;background: #3C8B43;'" :aria-valuenow="perizinan.total_progress" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <span class="align-self-center ml-1">{{ perizinan.total_progress ? perizinan.total_progress : 0 }}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="line-vertical"></div>
                <div class="chart">
                    <div class="chart-period" 
                        :style="'grid-template-columns: 91px repeat('+daylist.length+', 1fr)'">
                        <span v-for="(day, index) in daylist" :key="index">{{day}}</span>
                    </div>
                    <div class="chart-lines" 
                        :style="'grid-template-columns: 91px repeat('+daylist.length+', 1fr)'">
                        <span v-for="(day, index) in daylist" :key="index" :style="day == todayDate ? 'background:rgba(61, 141, 70, 0.1)':''"></span>
                    </div>
                    <ul class="chart-row-bars"  v-for="perizinan in project.perizinans" :key="perizinan.id" 
                        :style="'grid-template-columns: 91px repeat('+daylist.length+', 1fr)'">
                        <li class="chart-li" 
                            v-for="(syarat, index_perizinan_syarat) in perizinan.tasks" :key="index_perizinan_syarat"
                            :style="'grid-column:'+ diff(syarat.start_date) +'/'+ (diff(syarat.end_date)+1) +';'" @click="alert(perizinan.name,syarat)">
                            {{perizinan.name}}:<span class="font-400" style="margin-left:5px;">{{ syarat.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="!showAddPerizinan">
            <li class="nav-item">
                <a class="nav-link active" id="approved-tab" data-toggle="tab" href="#approved" role="tab" aria-controls="approved" aria-selected="true">Plan yang telah disetujui</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="pending-tab" data-toggle="tab" href="#pending" role="tab" aria-controls="pending" aria-selected="false">Plan yang belum disetujui</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContent" v-if="!showAddPerizinan">
            <div class="tab-pane fade pt-1 show active" id="approved" role="tabpanel" aria-labelledby="approved-tab">
                <div id="accordionTryout2">
                    <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;" 
                        v-for="(perizinan, index_perizinan) in project.perizinans" :key="index_perizinan">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="col">
                                    <div class="row">
                                        <img src="/assets/images/perizinan.png" alt="" class="img-fluid align-self-center" style="width: 42px; height: 42px;">
                                        <div class="col text-left">
                                            <p class="f-16 font-600 mb-0">{{ perizinan.name }}</p>
                                            <p class="f-12 mb-0">
                                                <span class="text-black">Waktu: </span><span class="text-primary mr-1 font-600">{{ perizinan.start_date | moment('D MMM YYYY') }} - {{ perizinan.end_date | moment('D MMM YYYY') }}</span>
                                                <span class="d-lg-none"><br></span>
                                                <span class="text-black">Biaya: </span><span class="text-primary mr-1 font-600">{{ perizinan.total_price | currency }}</span>
                                                <span class="text-black">Total Biaya: </span><span class="text-primary mr-1 font-600">{{ perizinan.total_biaya_keseluruhan | currency }}</span>
                                            </p>
                                            <div class="row">
                                                <div class="progress mb-0 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                    <div class="progress-bar" role="progressbar" :style="'width: '+perizinan.total_progress+'%;background: #3C8B43;'" :aria-valuenow="perizinan.total_progress" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <span class="align-self-center ml-1">{{ perizinan.total_progress }}%</span>
                                            </div>
                                            <div class="" v-if="perizinan.dependencies.length > 0">
                                                <p class="f-12 mb-0"><b>Dependencie:</b></p>
                                                <ul>
                                                    <li v-for="dependency in perizinan.dependencies" :key="dependency.id">{{ dependency.perizinan.name }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <!-- <div class="col align-self-center text-right" v-can="'pm'">
                                            <button class="btn btn-danger" @click="perizinan_delete(perizinan.id)">Hapus</button> &nbsp;
                                            <button class="btn btn-warning">Edit</button> 
                                        </div> -->
                                        <div class="align-self-center">
                                            <button data-toggle="collapse" :data-target="'#collapseTryout_' + index_perizinan" aria-expanded="false" :aria-controls="'collapse' + index_perizinan" class="btn pr-1 pl-0">
                                                <i class="f-20 fa fa-chevron-down"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div :id="'collapseTryout_' + index_perizinan" class="collapse row" data-parent="#accordionTryout2">
                                    <div class="col">
                                        <hr>
                                        <p class="f-14 font-600">PIC</p>
                                        <div v-for="(pic, index_perizinan_pic) in perizinan.pics" :key="index_perizinan_pic">
                                            <hr>
                                            <div class="form-group mb-1">
                                                <label for="" class="font-600 f-12">Nama</label>
                                                <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.name" placeholder="Masukkan Nama" readonly>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="form-group mb-0">
                                                        <label for="" class="font-600 f-12">No. Telepon</label>
                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.no_telp" placeholder="Masukkan No. Telepon" readonly>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <label for="" class="font-600 f-12">Jabatan</label>
                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.jabatan" placeholder="Masukkan Jabatan" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :id="'accordionSyarat_' + index_perizinan" class="mt-1">
                                            <div class="col pt-1 pl-0 pr-0" style="border-top: 1px solid rgba(34, 41, 47, 0.1);" v-for="(syarat, index_perizinan_syarat) in perizinan.tasks" :key="index_perizinan_syarat">
                                                <div class="row" :class="perizinan.tasks.length == index_perizinan_syarat + 1 ? '' : ' mb-1 '">
                                                    <img src="/assets/images/sub-syarat.png" alt="" class="img-fluid ml-1" style="width: 24px; height: 24px;">
                                                    <div class="col align-self-center">
                                                        <input type="text" class="border-0 w-50 f-16 font-600" v-model="syarat.name">
                                                        <p class="f-12 mb-0">
                                                            <span class="text-black">Waktu: </span><span class="text-primary mr-1 font-600">{{ syarat.start_date | moment('D MMM YYYY') }} - {{ syarat.end_date | moment('D MMM YYYY') }}</span>
                                                            <span class="d-lg-none"><br></span>
                                                            <span class="text-black">Biaya: </span><span class="text-primary mr-1 font-600">{{ syarat.biaya | currency }}</span>
                                                        </p>
                                                        <p class="text-primary mb-0 f-12 font-600">{{ syarat.step.toUpperCase() }}</p>
                                                        <div class="row">
                                                            <div class="progress mb-0 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                                <div class="progress-bar" role="progressbar" :style="'width: '+syarat.progress+'%;background: #3C8B43;'" :aria-valuenow="syarat.progress" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <span class="align-self-center ml-1">{{ syarat.progress }}%</span>
                                                        </div>
                                                        <hr>
                                                        <p class="f-14 font-600">PIC</p>
                                                        <div v-for="(pic, index_syarat_pic) in syarat.pics" :key="index_syarat_pic">
                                                            <hr>
                                                            <div class="form-group mb-1">
                                                                <label for="" class="font-600 f-12">Nama</label>
                                                                <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.name" placeholder="Masukkan Nama" readonly>
                                                            </div>
                                                            <div class="col">
                                                                <div class="row">
                                                                    <div class="form-group mb-0">
                                                                        <label for="" class="font-600 f-12">No. Telepon</label>
                                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.no_telp" placeholder="Masukkan No. Telepon" readonly>
                                                                    </div>
                                                                    <div class="form-group mb-0">
                                                                        <label for="" class="font-600 f-12">Jabatan</label>
                                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.jabatan" placeholder="Masukkan Jabatan" readonly>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col text-right">
                                                    </div>
                                                    <div class="align-self-center">
                                                        <button type="button" v-if="syarat.sub_tasks.length > 0" data-toggle="collapse" :data-target="'#collapseSyarat_perizinan' + index_perizinan + '_syarat_' + index_perizinan_syarat" 
                                                            aria-expanded="false" class="btn mr-1 pr-1 pl-0">
                                                            <i class="f-20 fa fa-chevron-down"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div :id="'collapseSyarat_perizinan' + index_perizinan + '_syarat_' + index_perizinan_syarat" class="collapse" :data-parent="'#accordionSyarat_' + index_perizinan">
                                                    <div class="col pl-4 pr-0 bg-light" v-for="(sub_syarat, index_sub_syarat) in syarat.sub_tasks" :key="index_sub_syarat">
                                                        <div class="row">
                                                            <!-- <i class="fa fa-circle f-10 align-self-center ml-1" :class="sub_syarat.checked == 1 ? ' text-primary' : ' text-black'"></i> -->
                                                            <img src="/assets/images/sub-syarat.png" alt="" class="img-fluid ml-1 align-self-center" style="width: 24px; height: 24px;">
                                                            <div class="col align-self-center pt-1 pb-1">
                                                                <input type="text" class="border-0 w-50 f-14 text-black bg-light" readonly v-model="sub_syarat.name">
                                                            </div>
                                                            <div class="col text-right pr-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="project.perizinans && project.perizinans.length == 0" class="text-center">Tidak ada data</p>
                </div>
            </div>
            <div class="tab-pane fade pt-1" id="pending" role="tabpanel" aria-labelledby="pending-tab">
                <div id="accordionTryout3">
                    <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;" 
                        v-for="(perizinan, index_perizinan) in project.perizinans_not_approved" :key="index_perizinan">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="col">
                                    <div class="row">
                                        <img src="/assets/images/perizinan.png" alt="" class="img-fluid align-self-center" style="width: 42px; height: 42px;">
                                        <div class="col text-left">
                                            <p class="f-16 font-600 mb-0">{{ perizinan.name }} <span class="badge badge-danger" v-if="perizinan.status_approval == 'rejected'">Rejected</span></p>
                                            <p class="f-12 mb-0">
                                                <span class="text-black">Waktu: </span><span class="text-primary mr-1 font-600">{{ perizinan.start_date | moment('D MMM YYYY') }} - {{ perizinan.end_date | moment('D MMM YYYY') }}</span>
                                                <span class="d-lg-none"><br></span>
                                                <span class="text-black">Biaya: </span><span class="text-primary mr-1 font-600">{{ perizinan.total_price | currency }}</span>
                                                <span class="text-black">Total Biaya: </span><span class="text-primary mr-1 font-600">{{ perizinan.total_biaya_keseluruhan | currency }}</span>
                                            </p>
                                            <div class="row">
                                                <div class="progress mb-0 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                    <div class="progress-bar" role="progressbar" :style="'width: '+perizinan.total_progress+'%;background: #3C8B43;'" :aria-valuenow="perizinan.total_progress" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <span class="align-self-center ml-1">{{ perizinan.total_progress }}%</span>
                                            </div>
                                            <div class="" v-if="perizinan.dependencies.length > 0">
                                                <p class="f-12 mb-0"><b>Dependencies:</b></p>
                                                <ul>
                                                    <li v-for="dependency in perizinan.dependencies" :key="dependency.id">{{ dependency.perizinan.name }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col align-self-center text-right" v-can="'pm'" v-if="perizinan.status_approval == 'waiting'">
                                            <button class="btn btn-danger" @click="perizinan_delete(perizinan)">Hapus</button> &nbsp;
                                            <button class="btn btn-warning" @click="perizinan_edit(perizinan), showAddPerizinan = true">Edit</button> 
                                        </div>
                                        <div class="col align-self-center text-right" v-can="'company'" v-if="perizinan.status_approval == 'waiting'">
                                            <button class="btn btn-primary" @click="perizinan_status(perizinan.id, 'approved')">Approve</button> &nbsp;
                                            <button class="btn btn-danger" @click="perizinan_status(perizinan.id, 'rejected')">Reject</button> 
                                        </div>
                                        <div class="align-self-center">
                                            <button data-toggle="collapse" :data-target="'#collapseTryout_' + index_perizinan" aria-expanded="false" :aria-controls="'collapse' + index_perizinan" class="btn pr-1 pl-0">
                                                <i class="f-20 fa fa-chevron-down"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div :id="'collapseTryout_' + index_perizinan" class="collapse row" data-parent="#accordionTryout2">
                                    <div class="col">
                                        <hr>
                                        <p class="f-14 font-600">PIC</p>
                                        <div v-for="(pic, index_perizinan_pic) in perizinan.pics" :key="index_perizinan_pic">
                                            <hr>
                                            <div class="form-group mb-1">
                                                <label for="" class="font-600 f-12">Nama</label>
                                                <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.name" placeholder="Masukkan Nama" readonly>
                                            </div>
                                            <div class="col">
                                                <div class="row">
                                                    <div class="form-group mb-0">
                                                        <label for="" class="font-600 f-12">No. Telepon</label>
                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.no_telp" placeholder="Masukkan No. Telepon" readonly>
                                                    </div>
                                                    <div class="form-group mb-0">
                                                        <label for="" class="font-600 f-12">Jabatan</label>
                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.jabatan" placeholder="Masukkan Jabatan" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div :id="'accordionSyarat_' + index_perizinan" class="mt-1">
                                            <div class="col pt-1 pl-0 pr-0" style="border-top: 1px solid rgba(34, 41, 47, 0.1);" v-for="(syarat, index_perizinan_syarat) in perizinan.tasks" :key="index_perizinan_syarat">
                                                <div class="row" :class="perizinan.tasks.length == index_perizinan_syarat + 1 ? '' : ' mb-1 '">
                                                    <img src="/assets/images/sub-syarat.png" alt="" class="img-fluid ml-1" style="width: 24px; height: 24px;">
                                                    <div class="col align-self-center">
                                                        <input type="text" class="border-0 w-50 f-16 font-600" v-model="syarat.name">
                                                        <p class="f-12 mb-0">
                                                            <span class="text-black">Waktu: </span><span class="text-primary mr-1 font-600">{{ syarat.start_date | moment('D MMM YYYY') }} - {{ syarat.end_date | moment('D MMM YYYY') }}</span>
                                                            <span class="d-lg-none"><br></span>
                                                            <span class="text-black">Biaya: </span><span class="text-primary mr-1 font-600">{{ syarat.biaya | currency }}</span>
                                                        </p>
                                                        <p class="text-primary mb-0 f-12 font-600">{{ syarat.step.toUpperCase() }}</p>
                                                        <div class="row">
                                                            <div class="progress mb-0 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                                <div class="progress-bar" role="progressbar" :style="'width: '+syarat.progress+'%;background: #3C8B43;'" :aria-valuenow="syarat.progress" aria-valuemin="0" aria-valuemax="100"></div>
                                                            </div>
                                                            <span class="align-self-center ml-1">{{ syarat.progress }}%</span>
                                                        </div>
                                                        <hr>
                                                        <p class="f-14 font-600">PIC</p>
                                                        <div v-for="(pic, index_syarat_pic) in syarat.pics" :key="index_syarat_pic">
                                                            <hr>
                                                            <div class="form-group mb-1">
                                                                <label for="" class="font-600 f-12">Nama</label>
                                                                <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.name" placeholder="Masukkan Nama" readonly>
                                                            </div>
                                                            <div class="col">
                                                                <div class="row">
                                                                    <div class="form-group mb-0">
                                                                        <label for="" class="font-600 f-12">No. Telepon</label>
                                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.no_telp" placeholder="Masukkan No. Telepon" readonly>
                                                                    </div>
                                                                    <div class="form-group mb-0">
                                                                        <label for="" class="font-600 f-12">Jabatan</label>
                                                                        <input type="text" class="border-0 pl-0 pt-0 pb-0" v-model="pic.jabatan" placeholder="Masukkan Jabatan" readonly>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col text-right">
                                                    </div>
                                                    <div class="align-self-center">
                                                        <button type="button" v-if="syarat.sub_tasks.length > 0" data-toggle="collapse" :data-target="'#collapseSyarat_perizinan' + index_perizinan + '_syarat_' + index_perizinan_syarat" 
                                                            aria-expanded="false" class="btn mr-1 pr-1 pl-0">
                                                            <i class="f-20 fa fa-chevron-down"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div :id="'collapseSyarat_perizinan' + index_perizinan + '_syarat_' + index_perizinan_syarat" class="collapse" :data-parent="'#accordionSyarat_' + index_perizinan">
                                                    <div class="col pl-4 pr-0 bg-light" v-for="(sub_syarat, index_sub_syarat) in syarat.sub_tasks" :key="index_sub_syarat">
                                                        <div class="row">
                                                            <img src="/assets/images/sub-syarat.png" alt="" class="img-fluid ml-1 align-self-center" style="width: 24px; height: 24px;">
                                                            <div class="col align-self-center pt-1 pb-1">
                                                                <input type="text" class="border-0 w-50 f-14 text-black bg-light" readonly v-model="sub_syarat.name">
                                                            </div>
                                                            <div class="col text-right pr-0">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="project.perizinans_not_approved && project.perizinans_not_approved.length == 0" class="text-center">Tidak ada data</p>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
    .autosuggest__results-container{
        border-radius: 4px;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        margin-left: 26px;
        position: absolute;
        width: 95%;
        z-index: 1;
        margin-top: 10px;
        cursor: pointer;
    }

    .autosuggest__results ul{
        list-style: none;
        font-size: 10px;
        padding: 0px;
        margin-bottom: 0;
        max-height: 150px;
        overflow: auto;
    }
    .autosuggest__results ul li{
        border-bottom: 1px solid #eee;
        padding: 8px 17px;
    }

    .autosuggest__results ul li:hover{
        background: #eee;
    }
</style>
<script>
export default {
    data() {
        return {
            project: {
                perizinans: [],
                perizinans_not_approved: []
            },
            showAddPerizinan: false,
            model_perizinan: {
                name: '',
                biaya: '',
                pics: [],
                syarats: [],
                id_parent: null,
                start_date: '',
                end_date: ''
            },
            date_range_perizinan: '',
            date_range_syarat: [],
            daylist: [],
            gantt_date_start_date: '',
            gantt_date_end_date: '',
            todayDate: this.$moment().format("DD/MM/YY"),
            suggestPIC: [
				{
				    data: []
				}
			],
            activePerizinan: null,
            activeSyarat: null,
            backupSuggest: [],
            currentPerizinan: null,
            dependencies: []
        }
    },
    methods: {
        terter(value){
            function terbilang(a){var c=" Satu Dua Tiga Empat Lima Enam Tujuh Delapan Sembilan Sepuluh Sebelas".split(" ");if(12>a)var b=c[a];else 20>a?b=c[a-10]+" Belas":100>a?(b=parseInt(String(a/10).substr(0,1)),b=c[b]+" Puluh "+c[a%10]):200>a?b="Seratus "+terbilang(a-100):1E3>a?(b=parseInt(String(a/100).substr(0,1)),b=c[b]+" Ratus "+terbilang(a%100)):2E3>a?b="Seribu "+terbilang(a-1E3):1E4>a?(b=parseInt(String(a/1E3).substr(0,1)),b=c[b]+" Ribu "+terbilang(a%1E3)):1E5>a?(b=parseInt(String(a/100).substr(0,2)),
            a%=1E3,b=terbilang(b)+" Ribu "+terbilang(a)):1E6>a?(b=parseInt(String(a/1E3).substr(0,3)),a%=1E3,b=terbilang(b)+" Ribu "+terbilang(a)):1E8>a?(b=parseInt(String(a/1E6).substr(0,4)),a%=1E6,b=terbilang(b)+" Juta "+terbilang(a)):1E9>a?(b=parseInt(String(a/1E6).substr(0,4)),a%=1E6,b=terbilang(b)+" Juta "+terbilang(a)):1E10>a?(b=parseInt(String(a/1E9).substr(0,1)),a%=1E9,b=terbilang(b)+" Milyar "+terbilang(a)):1E11>a?(b=parseInt(String(a/1E9).substr(0,2)),a%=1E9,b=terbilang(b)+" Milyar "+terbilang(a)):
            1E12>a?(b=parseInt(String(a/1E9).substr(0,3)),a%=1E9,b=terbilang(b)+" Milyar "+terbilang(a)):1E13>a?(b=parseInt(String(a/1E10).substr(0,1)),a%=1E10,b=terbilang(b)+" Triliun "+terbilang(a)):1E14>a?(b=parseInt(String(a/1E12).substr(0,2)),a%=1E12,b=terbilang(b)+" Triliun "+terbilang(a)):1E15>a?(b=parseInt(String(a/1E12).substr(0,3)),a%=1E12,b=terbilang(b)+" Triliun "+terbilang(a)):1E16>a&&(b=parseInt(String(a/1E15).substr(0,1)),a%=1E15,b=terbilang(b)+" Kuadriliun "+terbilang(a));a=b.split(" ");c=[];for(b=0;b<a.length;b++)""!=a[b]&&c.push(a[b]);return c.join(" ")}

            return terbilang(value);
        },
        perizinan_addPIC(){
            let _ = this
            _.model_perizinan.pics.push({
                name: '',
                no_telp: '',
                jabatan: ''
            })
        },
        perizinan_addSyarat(){
            let _ = this
            _.model_perizinan.syarats.push({
                name: '',
                subs: [],
                pics: [
                    {
                        name: '',
                        no_telp: '',
                        jabatan: ''
                    }
                ]
            })
        },
        perizinan_syarat_add_pic(x){
            let _ = this
            _.model_perizinan.syarats[x].pics.push({
                name: '',
                no_telp: '',
                jabatan: ''
            })
        },
        perizinan_syarat_remove_pic(index_syarat, x){
            let _ = this
            _.model_perizinan.syarats[index_syarat].pics.splice(x, 1)
        },
        perizinan_addSubSyarat(x){
            let _ = this
            _.model_perizinan.syarats[x].subs.push({
                name: '',
                checked: 0
            })
        },
        perizinan_deletePIC(x){
            let _ = this
            _.model_perizinan.pics.splice(x, 1);
        },
        perizinan_deleteSyarat(x){
            let _ = this
            _.model_perizinan.syarats.splice(x, 1);
        },
        perizinan_deleteSubSyarat(x, y){
            let _ = this
            _.model_perizinan.syarats[x].subs.splice(y, 1);
        },
        perizinan_reset(){
            let _ = this
            _.showAddPerizinan = false
            _.model_perizinan = {
                name: '',
                biaya: '',
                start_date: '',
                end_date: '',
                pics: [],
                syarats: [],
                id_parent: null
            }
            _.date_range_syarat = []
            _.date_range_perizinan = []
            _.dependencies = []
        },
        perizinan_get(){
            let _ = this

            _.axios.get('/project/perizinan?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.project.perizinans = resp.data.data
                    _.$forceUpdate()

                    let dateList = []

                    _.project.perizinans.forEach(perizinan => {
                        perizinan.tasks.forEach(syarat => {
                            dateList.push(syarat.start_date)
                            dateList.push(syarat.end_date)
                        });
                    });

                    dateList = dateList.sort()

                    if (dateList.length > 0) {
                        _.gantt_date_start_date = _.$moment(dateList[0]).format('YYYY-MM-DD')
                        _.gantt_date_end_date = _.$moment(dateList[dateList.length-1]).format('YYYY-MM-DD')

                        var getDaysArray = function(s,e) {for(var a=[],d=new Date(s);d<=e;d.setDate(d.getDate()+1)){ a.push(new Date(d));}return a;};
                        var daylist = getDaysArray(new Date(_.gantt_date_start_date),new Date(_.gantt_date_end_date));
                        daylist.map((v)=>v.toISOString().slice(0,10)).join("")
                        daylist.forEach(elm => {
                            _.daylist.push(_.$moment(elm).format("DD/MM/YY"))
                        });
                    }

                    _.$root.$emit('statusPerizinan', _.project.perizinans)
                })
                .catch(err => {
                    console.log(err)
                })
        },
        perizinan_get_not_approved(){
            let _ = this
            _.axios.get('/project/perizinan?id_project=' + _.$route.params.id_project + '&status_approval=not_approved' )
                .then(resp => {
                    _.project.perizinans_not_approved = resp.data.data
                    _.$forceUpdate()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        perizinan_edit(x){
            let _ = this

            let syarats = []
            for (let i = 0; i < x.tasks.length; i++) {
                syarats.push(x.tasks[i])
                syarats[i].subs = x.tasks[i].sub_tasks
                _.date_range_syarat[i] = [ _.$moment(x.tasks[i].start_date).format('YYYY-MM-DD'), _.$moment(x.tasks[i].end_date).format('YYYY-MM-DD') ]
            }
            _.date_range_perizinan = [ _.$moment(x.start_date).format('YYYY-MM-DD'), _.$moment(x.end_date).format('YYYY-MM-DD') ]
            _.model_perizinan = {
                id: x.id,
                name: x.name,
                biaya: x.total_price,
                start_date: x.start_date,
                end_date: x.end_date,
                pics: x.pics,
                syarats: syarats,
                id_parent: x.id_parent
            }
            _.dependencies = x.dependencies.map(x => x.perizinan)
            _.getTotalPrice()
        },
        perizinan_submit(){
            let _ = this

            if (_.model_perizinan.id) {
                let data = {
                    id: _.model_perizinan.id,
                    id_project: _.$route.params.id_project,
                    name: _.model_perizinan.name,
                    start_date: _.model_perizinan.start_date,
                    end_date: _.model_perizinan.end_date,
                    total_price: _.model_perizinan.biaya,
                    pics: JSON.stringify(_.model_perizinan.pics),
                    tasks: JSON.stringify(_.model_perizinan.syarats),
                    dependencies: JSON.stringify(_.dependencies)
                }

                _.axios.put('/project/perizinan/' + _.model_perizinan.id, data)
                    .then(resp => {
                        console.log(resp.data)
                        _.perizinan_reset()
                        _.perizinan_get_not_approved()
                        _.getPic()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                let data = {
                    id_project: _.$route.params.id_project,
                    name: _.model_perizinan.name,
                    start_date: _.model_perizinan.start_date,
                    end_date: _.model_perizinan.end_date,
                    total_price: _.model_perizinan.biaya,
                    pics: JSON.stringify(_.model_perizinan.pics),
                    tasks: JSON.stringify(_.model_perizinan.syarats),
                    dependencies: JSON.stringify(_.dependencies)
                }

                _.axios.post('/project/perizinan', data)
                    .then(resp => {
                        console.log(resp.data)
                        _.perizinan_reset()
                        _.perizinan_get_not_approved()
                        _.getPic()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }            
        },
        perizinan_delete(x){
            let _ = this

            _.$swal.fire({
                title: 'Hapus Izin',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Izin <b>' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/perizinan/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    if (x.status_approval != 'approved') {
                                        _.perizinan_get_not_approved()
                                    } else {
                                        _.perizinan_get()
                                    }
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Izin',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        perizinan_status(x, status) {
            let _ = this

            _.axios.post('/project/perizinan/' + x + '/' + status)
                .then( resp => {
                    console.log(resp.data)
                    _.perizinan_get_not_approved()
                    _.perizinan_get()
                })
        },
        diff(x) {
            return (this.$moment(this.gantt_date_start_date).diff(x, 'days')*-1+1);
        },
        alert(izin,task) {
            this.$swal.fire({
                title: izin,
                text: task.name + ' (' + this.$moment(task.start_date).format('D MMM YYYY') + ' - ' + this.$moment(task.end_date).format('D MMM YYYY') + ')',
                icon: 'info',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'OK'
            })
        },
        getTotalPrice() {
            let _ = this
            let total = 0
            
            total = total + _.model_perizinan.biaya
            _.model_perizinan.syarats.forEach(elm => {
                total = total + elm.biaya
            });

            _.model_perizinan.total_biaya = total
        },
        getPic() {
            let _ = this
            _.axios.get('project/pic?id_project=' + _.$route.params.id_project)
                .then(resp => {
                    _.suggestPIC[0].data = resp.data.data
                    _.backupSuggest = resp.data.data
                })
        },
        selectedPICPerizinan(result) {
            let _ = this
            _.model_perizinan.pics[_.activePerizinan].name = result.item.name
            _.model_perizinan.pics[_.activePerizinan].no_telp = result.item.phone_number
            _.model_perizinan.pics[_.activePerizinan].jabatan = result.item.position
        },
        selectedPICSyarat(result) {
            let _ = this
            _.model_perizinan.syarats[_.currentPerizinan].pics[_.activeSyarat].name = result.item.name
            _.model_perizinan.syarats[_.currentPerizinan].pics[_.activeSyarat].no_telp = result.item.phone_number
            _.model_perizinan.syarats[_.currentPerizinan].pics[_.activeSyarat].jabatan = result.item.position
        },
        suggestLabel(result) {
		    return result.item.name
		},
        searchPIC(input) {
            this.suggestPIC[0].data = this.backupSuggest
            if (input != '') {
                this.suggestPIC[0].data = this.suggestPIC[0].data.filter((item) => item.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
            }
		},
        getIdPICPerizinan(index) {
            this.activePerizinan = index
        },
        getIdPICSyarat(indexPerizinan, index) {
            this.currentPerizinan = indexPerizinan
            this.activeSyarat = index
        }
    },
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.perizinan_get()
        _.perizinan_get_not_approved()
        _.getPic()
    }
}
</script>
