<template>
    <div class="content-body">
        <div class="mb-2 text-primary">
            <!-- <router-link to="/"> -->
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.1875 0.1875H0.8125C0.466797 0.1875 0.1875 0.466797 0.1875 0.8125V15.1875C0.1875 15.5332 0.466797 15.8125 0.8125 15.8125H15.1875C15.5332 15.8125 15.8125 15.5332 15.8125 15.1875V0.8125C15.8125 0.466797 15.5332 0.1875 15.1875 0.1875ZM5.1875 12.5312C5.1875 12.6172 5.11719 12.6875 5.03125 12.6875H3.46875C3.38281 12.6875 3.3125 12.6172 3.3125 12.5312V3.46875C3.3125 3.38281 3.38281 3.3125 3.46875 3.3125H5.03125C5.11719 3.3125 5.1875 3.38281 5.1875 3.46875V12.5312ZM8.9375 7.0625C8.9375 7.14844 8.86719 7.21875 8.78125 7.21875H7.21875C7.13281 7.21875 7.0625 7.14844 7.0625 7.0625V3.46875C7.0625 3.38281 7.13281 3.3125 7.21875 3.3125H8.78125C8.86719 3.3125 8.9375 3.38281 8.9375 3.46875V7.0625ZM12.6875 8.46875C12.6875 8.55469 12.6172 8.625 12.5312 8.625H10.9688C10.8828 8.625 10.8125 8.55469 10.8125 8.46875V3.46875C10.8125 3.38281 10.8828 3.3125 10.9688 3.3125H12.5312C12.6172 3.3125 12.6875 3.38281 12.6875 3.46875V8.46875Z" fill="#3C8B43"/>
                </svg>
                <span class="menu-title align-self-center ml-1"> View Report / {{company.user.name}} / {{project.project_name}}</span>
            <!-- </router-link> -->
        </div>
        
        <div class="card w-100 ">
            <div class="card-content">
                <div class="card-body ic-statistic">
                    <div class="col">
                        <div class="row">
                            <div style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;padding-top:8px;" class="text-center">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M15.1875 0.1875H0.8125C0.466797 0.1875 0.1875 0.466797 0.1875 0.8125V15.1875C0.1875 15.5332 0.466797 15.8125 0.8125 15.8125H15.1875C15.5332 15.8125 15.8125 15.5332 15.8125 15.1875V0.8125C15.8125 0.466797 15.5332 0.1875 15.1875 0.1875ZM5.1875 12.5312C5.1875 12.6172 5.11719 12.6875 5.03125 12.6875H3.46875C3.38281 12.6875 3.3125 12.6172 3.3125 12.5312V3.46875C3.3125 3.38281 3.38281 3.3125 3.46875 3.3125H5.03125C5.11719 3.3125 5.1875 3.38281 5.1875 3.46875V12.5312ZM8.9375 7.0625C8.9375 7.14844 8.86719 7.21875 8.78125 7.21875H7.21875C7.13281 7.21875 7.0625 7.14844 7.0625 7.0625V3.46875C7.0625 3.38281 7.13281 3.3125 7.21875 3.3125H8.78125C8.86719 3.3125 8.9375 3.38281 8.9375 3.46875V7.0625ZM12.6875 8.46875C12.6875 8.55469 12.6172 8.625 12.5312 8.625H10.9688C10.8828 8.625 10.8125 8.55469 10.8125 8.46875V3.46875C10.8125 3.38281 10.8828 3.3125 10.9688 3.3125H12.5312C12.6172 3.3125 12.6875 3.38281 12.6875 3.46875V8.46875Z" fill="#3C8B43"/>
                                </svg>
                            </div>
                            <div class="col align-self-center">
                                <p class="font-600 f-20 mb-0">{{project.project_name}}</p>
                            </div>
                            <div class="col-lg-6 col-xs-12 text-right p-0">
                                <div class="d-lg-none mt-1"></div>
                                <button class="btn btn-blue col-lg-5 col-xs-12" @click="$router.push('/notifikasi')">
                                    Buat Notif
                                </button>
                                &nbsp;
                                <div class="d-lg-none mb-1"></div>
                                <button class="btn btn-primary col-lg-5 col-xs-12" @click="download()" :disabled="isLoading" title="Download as PDF">
                                    Download PDF <i v-if="isLoading" class="fa fa-spinner fa-spin"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="dashboard-tab" data-toggle="tab" href="#dashboard" role="tab" aria-controls="dashboard" aria-selected="true">Dashboard</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="perizinan-tab" data-toggle="tab" href="#perizinan" role="tab" aria-controls="perizinan" aria-selected="false">Perizinan</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="pembangunan-tab" data-toggle="tab" href="#pembangunan" role="tab" aria-controls="pembangunan" aria-selected="false">Pembangunan</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="penjualan-tab" data-toggle="tab" href="#penjualan" role="tab" aria-controls="penjualan" aria-selected="false">Penjualan</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="keuangan-tab" data-toggle="tab" href="#keuangan" role="tab" aria-controls="keuangan" aria-selected="false">Keuangan</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="konsumen-tab" data-toggle="tab" href="#konsumen" role="tab" aria-controls="konsumen" aria-selected="false">Konsumen</a>
                        </li>
                        <li class="nav-item" v-can="'pm'">
                            <a class="nav-link" id="jurnal-tab" data-toggle="tab" href="#jurnal" role="tab" aria-controls="jurnal" aria-selected="false">Jurnal</a>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade pt-1 show active" id="dashboard" role="tabpanel" aria-labelledby="dashboard-tab">
                            <Dashboard></Dashboard>
                        </div>
                        <div class="tab-pane fade pt-1" id="perizinan" role="tabpanel" aria-labelledby="perizinan-tab">
                            <Perizinan></Perizinan>
                        </div>
                        <div class="tab-pane fade pt-1" id="pembangunan" role="tabpanel" aria-labelledby="pembangunan-tab">
                            <Pembangunan></Pembangunan>
                        </div>
                        <div class="tab-pane fade pt-1 " id="penjualan" role="tabpanel" aria-labelledby="penjualan-tab">
                            <Penjualan></Penjualan>
                        </div>
                        <div class="tab-pane fade pt-0" id="keuangan" role="tabpanel" aria-labelledby="keuangan-tab">
                            <Keuangan></Keuangan>
                        </div>
                        <div class="tab-pane fade pt-1" id="konsumen" role="tabpanel" aria-labelledby="konsumen-tab">
                            <BBKonsumen></BBKonsumen>
                        </div>
                        <div class="tab-pane fade pt-1 text-center" id="jurnal" role="tabpanel" aria-labelledby="jurnal-tab">
                            <Jurnal></Jurnal>
                        </div>
                    </div>
                </div>
            </div>
        </div>           
    </div>
</template>

<script>

import Dashboard from '@/views/project/detail/dashboard/Index.vue'
import Penjualan from '@/views/project/detail/penjualan/Index.vue'
import Perizinan from '@/views/project/detail/perizinan/Index.vue'
import BBKonsumen from '@/views/project/detail/bb_konsumen/Index.vue'
import Pembangunan from '@/views/project/detail/pembangunan/Index.vue'
import Jurnal from '@/views/project/detail/jurnal/Index.vue'
import Keuangan from '@/views/project/detail/keuangan/Index.vue'

export default {
    components: {
        Dashboard,
        Penjualan,
        Perizinan,
        BBKonsumen,
        Pembangunan,
        Keuangan,
        Jurnal
    },
	data() {
		return {
            date_now: new Date(),
            company: {
                user: {}
            },
            project: {},
            isLoading: false
		}
	},
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.getDetailCompany()
        _.getDetailProject()
    },
    methods: {
        getDetailCompany(){
            let _ = this
            _.axios.get('/company/' + _.$route.params.id_company)
                .then(resp => {
                    _.company = resp.data.data
                })
        },
        getDetailProject(){
            let _ = this
            _.axios.get('/project/' + _.$route.params.id_project)
                .then(resp => {
                    _.project = resp.data.data
                })
        },
        download() {
            let _ = this
            _.isLoading = true
            _.axios.get('/report/download_pdf?id='+_.$route.params.id_project+'&year=' + this.$moment().format('Y'), {responseType: 'arraybuffer'})
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/pdf' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'ProMIT - '+_.project.project_name+'.pdf'
                    link.click()
                    _.isLoading = false
                })
                .catch(() => {
                    _.isLoading = false
                })
        }
    }
}
</script>
