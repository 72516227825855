<template>
    <div class="bg-white" style="min-height: 100vh">
        <div class="col">
            <div class="row" style="height: 100%; min-height: 100vh;">
                <div class="col-xs-12 col-lg-6 p-lg-4 p-2">
                    <p style="font-size: 24px;"><span class="fa fa-arrow-left mr-1 f-18 pointer" @click="$router.back()"></span><b>Register</b></p>
                    <div class="alert-danger p-1 text-center" style="border: 1px solid #FF9D9D; border-radius: 4px;" v-if="authFalse != 0">
                        <div v-for="(v, k) in errors" :key="k">
                            <p v-for="error in v" :key="error" class="mb-0 text-danger" v-html="error"></p>
                        </div>
                    </div>
                    <section v-if="state == 1">
                        <form @submit.prevent="isValidate()" class="mt-3">
                            <ValidationObserver ref="observer" v-slot="{ invalid }">
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider name="Nama Direktur" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">Nama Direktur</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.direktur_name" placeholder="Masukkan Nama" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="Nama Komisaris" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">Nama Komisaris</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.komisaris_name" placeholder="Masukkan Nama" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="Alamat" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">Alamat</label>
                                                    <textarea name="" id="" cols="30" rows="3" class="form-control" v-model="model.alamat"
                                                        style="border: 0px; border-bottom: 1px solid #ccc;" placeholder="Masukkan Alamat"></textarea>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="NIK Direktur Utama" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">NIK Direktur Utama</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.direktur_nik" placeholder="Masukkan NIK" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                    <div class="image-upload">
                                                        <label for="upload-direktur-nik" class="f-12 mt-1 text-black">
                                                            <span class="fa fa-file mr-1"></span><u>{{ file_direktur_nik ? file_direktur_nik.substring(0, 14) + '...' : 'Unggah Dokumen' }}</u>
                                                        </label>
                                                        <input id="upload-direktur-nik" type="file" class="d-none" v-on:change="upload_direktur_nik"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="NPWP Direktur Utama" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">NPWP Direktur Utama</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.direktur_npwp" placeholder="Masukkan NPWP" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                    <div class="image-upload">
                                                        <label for="upload-direktur-npwp" class="f-12 mt-1 text-black">
                                                            <span class="fa fa-file mr-1"></span><u>{{ file_direktur_npwp ? file_direktur_npwp.substring(0, 14) + '...' : 'Unggah Dokumen' }}</u>
                                                        </label>
                                                        <input id="upload-direktur-npwp" type="file" class="d-none" v-on:change="upload_direktur_npwp"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col">
                                        <ValidationProvider name="Nama Perusahaan" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">Nama Perusahaan</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.name" placeholder="Masukkan Nama Perusahaan" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="No Akta Perusahaan" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">No Akta Perusahaan</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.no_akta" placeholder="Masukkan No Akta Perusahaan" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                    <div class="image-upload">
                                                        <label for="upload-no-akta" class="f-12 mt-1 text-black">
                                                            <span class="fa fa-file mr-1"></span><u>{{ file_no_akta ? file_no_akta.substring(0, 14) + '...' : 'Unggah Dokumen' }}</u>
                                                        </label>
                                                        <input id="upload-no-akta" type="file" class="d-none" v-on:change="upload_no_akta"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="No SK Kemenkumham" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">No SK Kemenkumham</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.no_sk" placeholder="Masukkan No SK Kemenkumham" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                    <div class="image-upload">
                                                        <label for="upload-no-sk" class="f-12 mt-1 text-black">
                                                            <span class="fa fa-file mr-1"></span><u>{{ file_no_sk ? file_no_sk.substring(0, 14) + '...' : 'Unggah Dokumen' }}</u>
                                                        </label>
                                                        <input id="upload-no-sk" type="file" class="d-none" v-on:change="upload_no_sk"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                        <ValidationProvider name="No NIB" rules="required" v-slot="{ errors }">
                                            <div class="col p-0">
                                                <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                    <label class="font-bold f-14">No NIB</label>
                                                    <input type="text" id="name" class="form-control" v-model="model.no_nib" placeholder="Masukkan No NIB" 
                                                        style="border: 0px; border-bottom: 1px solid #ccc;">
                                                    <div class="image-upload">
                                                        <label for="upload-no-nib" class="f-12 mt-1 text-black">
                                                            <span class="fa fa-file mr-1"></span><u>{{ file_no_nib ? file_no_nib.substring(0, 14) + '...' : 'Unggah Dokumen' }}</u>
                                                        </label>
                                                        <input id="upload-no-nib" type="file" class="d-none" v-on:change="upload_no_nib"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <ValidationProvider name="file" rules="required">
                                    <input type="hidden" v-model="file_direktur_nik">
                                </ValidationProvider>
                                <ValidationProvider name="file2" rules="required">
                                    <input type="hidden" v-model="file_direktur_npwp">
                                </ValidationProvider>
                                <ValidationProvider name="file3" rules="required">
                                    <input type="hidden" v-model="file_no_akta">
                                </ValidationProvider>
                                <ValidationProvider name="file4" rules="required">
                                    <input type="hidden" v-model="file_no_sk">
                                </ValidationProvider>
                                <ValidationProvider name="file5" rules="required">
                                    <input type="hidden" v-model="file_no_nib">
                                </ValidationProvider>
                                <ValidationProvider name="file6" rules="required">
                                    <input type="hidden" v-model="isLoading">
                                </ValidationProvider>                                

                                <div class="VueCarousel-dot-container text-center">
                                    <button type="button" class="VueCarousel-dot VueCarousel-dot--active btn btn-primary" style="padding: 1px; width: 20px !important; margin-right: 5px; height: 10px; background-color: #3C8B43 !important;"></button>
                                    <button type="button" class="VueCarousel-dot btn btn-secondary mr-1" style="padding: 5px; width: 10px; height: 10px;"></button>
                                </div>
                                <button type="submit" class="btn btn-primary waves-effect waves-light w-100 mt-2" :disabled="invalid">
                                    Selanjutnya
                                </button>
                            </ValidationObserver>
                        </form>
                    </section>
                    <section v-if="state == 2">
                        <form @submit.prevent="register()" class="mt-3">
                            <ValidationObserver ref="observer2" v-slot="{ invalid }">
                                <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                    <div class="col p-0">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label class="font-bold f-14">Email</label>
                                            <input type="text" id="name" class="form-control" v-model="model.email" placeholder="Masukkan Email" 
                                                style="border: 0px; border-bottom: 1px solid #ccc;">
                                        </div>
                                    </div>
                                </ValidationProvider>
                                <div class="row">
                                    <div class="col">
                                        <ValidationProvider name="password" rules="required|min:6" v-slot="{ errors }">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Password</label>
                                                <input type="password" v-model="model.password" class="form-control" placeholder="Masukkan Password"
                                                    style="border: 0px; border-bottom: 1px solid #ccc;">
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col">
                                        <ValidationProvider name="Confirm Password" rules="required|confirmed:password" v-slot="{ errors }">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Konfirmasi Password</label>
                                                <input type="password" v-model="model.confirm_password" class="form-control" placeholder="Konfirmasi Password"
                                                    style="border: 0px; border-bottom: 1px solid #ccc;">
                                            </div>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="VueCarousel-dot-container text-center">
                                    <button type="button" class="VueCarousel-dot btn btn-secondary" style="padding: 5px; width: 10px; height: 10px; margin-right: 5px;" @click="state=1"></button>
                                    <button type="button" class="VueCarousel-dot VueCarousel-dot--active btn btn-primary" style="padding: 1px; width: 20px !important; height: 10px; background-color: #3C8B43 !important;"></button>
                                </div>
                                <button type="submit" class="btn btn-primary waves-effect waves-light w-100 mt-2" :disabled="isLoading || invalid">
                                    <div v-if="isLoading">Daftar <i class="fa fa-spinner fa-spin"></i></div>
                                    <div v-else>Daftar</div>
                                </button>
                            </ValidationObserver>
                        </form>
                    </section>          
                </div>
                <div class="col-xs-12 col-lg-6 d-none d-lg-block p-0">
                    <div class="image-promit-signup"></div>
                    <div style="position: absolute; top: 30%; width: 100%;" class="d-none d-lg-block">
                        <p class="text-center"><img src="/assets/img/logo-login.png" alt=""></p>
                        <p class="text-center text-white" style="font-size: 42px; line-height: 2.5rem;"><b>Welcome to</b> Pro<b>MIT</b></p>
                        <p class="text-center text-white" style="font-size: 26px;">Start manage your projects</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    data() {
        return {
            model: {},
            state: 1,

            file_direktur_nik: null,
            file_direktur_npwp: null,
            file_no_akta: null,
            file_no_sk: null,
            file_no_nib: null,
            isLoading: false,
            authFalse: 0,
            errors: []
        }
    },
	methods: {
        async isValidate() {
            let _ = this  
            const isValid = await this.$refs.observer.validate()
            if (isValid){
                _.state = 2
            }
        },
		async register() {
            let _ = this  
            const isValid = await this.$refs.observer2.validate()
            if (isValid){
                _.isLoading = true
                let FData =  new FormData()
                for (let i in _.model){
                    if (_.model[i] != null){
                        FData.append(i, _.model[i])
                    }
                }

                _.axios.post( '/company', FData )
                    .then(()=>{
                        _.$router.push('/login')
                    })
                    .catch( err =>{
                        _.authFalse = 1
                        _.errors = err.response.data.errors
                        _.isLoading = false
                    })
            }
		},
        upload_direktur_nik(ev) {
            const file = ev.target.files[0]
            this.model.file_direktur_nik = file
            this.file_direktur_nik = file.name
        },
        upload_direktur_npwp(ev) {
            const file = ev.target.files[0]
            this.model.file_direktur_npwp = file
            this.file_direktur_npwp = file.name
        },
        upload_no_akta(ev) {
            const file = ev.target.files[0]
            this.model.file_no_akta = file
            this.file_no_akta = file.name
        },
        upload_no_sk(ev) {
            const file = ev.target.files[0]
            this.model.file_no_sk = file
            this.file_no_sk = file.name
        },
        upload_no_nib(ev) {
            const file = ev.target.files[0]
            this.model.file_no_nib = file
            this.file_no_nib = file.name
        }
	}
}
</script>