<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Laba Rugi
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-center f-25 font-700">
            Laporan Laba Rugi Proyek<br>
            <date-picker
                v-model="year"
                format="YYYY"
                type="year"
                :input-class="'form-control mx-auto mt-1 f-20 text-left'"
                value-type="YYYY"
                placeholder="Pilih Tahun"
                style="width: 90px;">
            </date-picker>
        </p>
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th class="text-center bg-light f-14" style="width: 100px;">Kode</th>
                    <th class="text-center bg-light f-14" style="width: 50%;">Transaksi</th>
                    <th class="text-center bg-light f-14" colspan="2">Nominal</th>
                    <th class="text-center bg-light f-14">Persentase</th>
                </tr>
                <tr v-for="(data, index) in model.pendapatan" :key="index">
                    <td class="text-center">{{ data.kode }}</td>
                    <td>{{ data.name }}</td>
                    <td>Rp</td>
                    <td class="text-right">{{ data.total | numFormat }}</td>
                    <td class="text-center">{{ data.total / total_pendapatan * 100 | numFormat }}%</td>
                </tr>
                <tr>
                    <td colspan="2" class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Total Pendapatan Proyek</td>
                    <td class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Rp</td>
                    <td class="text-right font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_pendapatan | numFormat }}</td>
                    <td class="text-center font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">100%</td>
                </tr>
            </table>
        </div>
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th class="text-center bg-light f-14" style="width: 100px;">Kode</th>
                    <th class="text-center bg-light f-14" style="width: 50%;">Transaksi</th>
                    <th class="text-center bg-light f-14" colspan="2">Nominal</th>
                    <th class="text-center bg-light f-14">Persentase</th>
                </tr>
                <tr v-for="(data, index) in model.selain_pendapatan" :key="index">
                    <td class="text-center">{{ data.kode }}</td>
                    <td>{{ data.name }}</td>
                    <td>Rp</td>
                    <td class="text-right">{{ data.total | numFormat }}</td>
                    <td class="text-center">{{ data.total / total_selain_pendapatan * 100 | numFormat }}%</td>
                </tr>
                <tr>
                    <td colspan="2" class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Total Pengeluaran Proyek</td>
                    <td class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Rp</td>
                    <td class="text-right font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_selain_pendapatan | numFormat }}</td>
                    <td class="text-center font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">100%</td>
                </tr>
                <tr>
                    <td colspan="2" class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Total Profit</td>
                    <td class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Rp</td>
                    <td class="text-right font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_pendapatan - total_selain_pendapatan | numFormat }}</td>
                    <td class="text-center font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">100%</td>
                </tr>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            model: {},
            year: this.$moment().format('Y'),
            total_pendapatan: 0,
            total_selain_pendapatan: 0,
        }
    },
    methods: {
        get()
        {
            let _ = this
            _.axios.get('/report/laba_rugi?id_project=' + _.$route.params.id_project + '&year=' + _.year)
                .then( resp => {
                    _.model = resp.data.data
                    _.total_pendapatan = 0
                    _.total_selain_pendapatan = 0

                    for (let index = 0; index < _.model.pendapatan.length; index++) {
                        _.total_pendapatan += _.model.pendapatan[index].total
                    }

                    for (let index = 0; index < _.model.selain_pendapatan.length; index++) {
                        _.total_selain_pendapatan += _.model.selain_pendapatan[index].total
                    }
                })
        }
    },
    mounted() {
        this.get()
    },
    watch: {
        year: function()
        {
            this.get();
        }
    }
}
</script>