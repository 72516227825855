var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-white",staticStyle:{"min-height":"100vh"}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row",staticStyle:{"height":"100%","min-height":"100vh"}},[_c('div',{staticClass:"col-xs-12 col-lg-6 p-lg-4 p-2"},[_c('p',{staticStyle:{"font-size":"24px"}},[_c('span',{staticClass:"fa fa-arrow-left mr-1 f-18 pointer",on:{"click":function($event){return _vm.$router.back()}}}),_c('b',[_vm._v("Register")])]),_c('form',{staticClass:"mt-3",on:{"submit":function($event){$event.preventDefault();return _vm.register()}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"Nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Nama")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.name),expression:"model.name"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"type":"text","placeholder":"Masukkan Nama"},domProps:{"value":(_vm.model.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "name", $event.target.value)}}})])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"NIK","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("NIK")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.nik),expression:"model.nik"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"type":"text","placeholder":"Masukkan NIK"},domProps:{"value":(_vm.model.nik)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "nik", $event.target.value)}}})])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Luas Tanah","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Luas Tanah")]),_c('vue-numeric',{staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"separator":".","placeholder":"Masukkan Luas Tanah"},model:{value:(_vm.model.luas_tanah),callback:function ($$v) {_vm.$set(_vm.model, "luas_tanah", $$v)},expression:"model.luas_tanah"}})],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nilai Proyek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Nilai Proyek")]),_c('vue-numeric',{staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"separator":".","currency":"Rp","placeholder":"Masukkan Nilai Proyek"},model:{value:(_vm.model.nilai),callback:function ($$v) {_vm.$set(_vm.model, "nilai", $$v)},expression:"model.nilai"}})],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Alamat")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.alamat),expression:"model.alamat"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"cols":"30","rows":"3","placeholder":"Masukkan Alamat"},domProps:{"value":(_vm.model.alamat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "alamat", $event.target.value)}}})])])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.email),expression:"model.email"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"type":"text","placeholder":"Masukkan Email"},domProps:{"value":(_vm.model.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "email", $event.target.value)}}})])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nama Perusahaan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Nama Perusahaan")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.id_company),expression:"model.id_company"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.model, "id_company", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Pilih Perusahaan")]),_vm._l((_vm.companies),function(company,index){return _c('option',{key:index,domProps:{"value":company.id}},[_vm._v(" "+_vm._s(company.user.name)+" ")])})],2)])])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Jumlah Unit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Jumlah Unit")]),_c('vue-numeric',{staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"separator":".","placeholder":"Masukkan Jumlah Unit"},model:{value:(_vm.model.jumlah_unit),callback:function ($$v) {_vm.$set(_vm.model, "jumlah_unit", $$v)},expression:"model.jumlah_unit"}})],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Nama Proyek","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Nama Proyek")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.project_name),expression:"model.project_name"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"type":"text","placeholder":"Masukkan Nama Proyek"},domProps:{"value":(_vm.model.project_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "project_name", $event.target.value)}}})])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.password),expression:"model.password"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"type":"password","placeholder":"Masukkan Password"},domProps:{"value":(_vm.model.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "password", $event.target.value)}}})])]}}],null,true)})],1),_c('div',{staticClass:"col"},[_c('ValidationProvider',{attrs:{"name":"Confirm Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"form-group",class:{ 'validation-warning': errors[0] }},[_c('label',{staticClass:"font-bold f-14"},[_vm._v("Konfirmasi Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.confirm_password),expression:"model.confirm_password"}],staticClass:"form-control",staticStyle:{"border":"0px","border-bottom":"1px solid #ccc"},attrs:{"type":"password","placeholder":"Konfirmasi Password"},domProps:{"value":(_vm.model.confirm_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "confirm_password", $event.target.value)}}})])]}}],null,true)})],1)]),_c('button',{staticClass:"btn btn-primary waves-effect waves-light w-100",attrs:{"type":"submit","disabled":_vm.isLoading || invalid}},[(_vm.isLoading)?_c('div',[_vm._v("Daftar "),_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',[_vm._v("Daftar")])])]}}])})],1)]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-xs-12 col-lg-6 d-none d-lg-block p-0"},[_c('div',{staticClass:"image-promit-signup"}),_c('div',{staticClass:"d-none d-lg-block",staticStyle:{"position":"absolute","top":"30%","width":"100%"}},[_c('p',{staticClass:"text-center"},[_c('img',{attrs:{"src":"/assets/img/logo-login.png","alt":""}})]),_c('p',{staticClass:"text-center text-white",staticStyle:{"font-size":"42px","line-height":"2.5rem"}},[_c('b',[_vm._v("Welcome to")]),_vm._v(" Pro"),_c('b',[_vm._v("MIT")])]),_c('p',{staticClass:"text-center text-white",staticStyle:{"font-size":"26px"}},[_vm._v("Start manage your projects")])])])}]

export { render, staticRenderFns }