<template>
    <section>
        <p class="f-24">
            <span class="font-600">{{ date_now | moment('D MMMM Y') }}, </span>
            <span class="text-secondary">{{ date_now | moment('dddd') }}</span>
        </p>
        <!-- <p class="f-24 font-700 text-black text-center">Fase Persiapan</p>
        <div class="wrapper-fase d-none d-lg-block">
            <div class="progress-wrapper">
                <div class="circle active">
                    <h5 class="text-primary active">Fase Persiapan</h5>
                </div>
                <div class="circle not-yet">
                    <h5 class="text-secondary">Fase Pengondisian</h5>
                </div>
                <div class="circle not-yet">
                    <h5 class="text-secondary">Fase Penjualan</h5>
                </div>
                <div class="circle not-yet">
                    <h5 class="text-secondary">Fase Hunian</h5>
                </div>
                <div class="circle not-yet">
                    <h5 class="text-secondary">Fase Kepuasan</h5>
                </div>
            </div>
        </div> -->
        <div class="row">
            <div class="col-lg-6 col-xs-12 mt-1">
                <p class="f-24 font-700 text-black">Status Perizinan</p>
                <div class="card bg-light mb-1" v-for="perizinan in perizinans" :key="perizinan.id">
                    <div class="card-body">
                        <div class="row">
                            <div class="box-logo ml-1">PT</div>
                            <div class="col align-self-center">
                                <p class="mb-0 f-14">{{ perizinan.name }}</p> 
                                <p class="mb-0 f-14" v-if="perizinan.end_date">{{ getDiffInDays(perizinan.end_date) }} Hari Lagi Pada <span class="text-danger">{{ perizinan.end_date | moment('DD MMMM YYYY')}}</span></p>
                            </div>
                            <div class="col-2">
                                <ProgressCircle :progress="perizinan.total_progress"></ProgressCircle>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="text-center" v-if="perizinans.length == 0">Tidak ada data</p>

                <p class="f-24 font-700 text-black">Master Kas</p>
                <ul>
                    <li v-for="kas in master_kas" :key="kas.id">{{kas.nama_bank}}</li>
                </ul>
                <p class="f-24 font-700 text-black">Profit</p>
                <div class="row">
                    <div class="col-4">
                        <div class="badge badge-light-primary rounded f-14 mb-1">Plan</div>
                        <p>{{ 99999999 | currency }}</p>
                    </div>
                    <div class="col-4">
                        <div class="badge badge-light-success rounded f-14 mb-1">Realisasi</div>
                        <p>{{ 99999999 | currency }}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 mt-1">
                <p class="f-24 font-700 text-black">Realisasi Saat Ini</p>
                <div class="table-responsive mt-1">
                    <table class="table">
                        <tr>
                            <th class="text-center f-14 font-600" rowspan="2">Kriteria Performance</th>
                            <th class="text-center f-14 font-600" colspan="2">
                                Week 1 <br>
                                <small>
                                    {{ penjualan.week_range.w1[0] | moment('DD MMM YYYY') }} -
                                    {{ penjualan.week_range.w1[1] | moment('DD MMM YYYY') }}
                                </small>
                            </th>
                            <th class="text-center f-14 font-600" colspan="2">
                                Week 2 <br>
                                <small>
                                    {{ penjualan.week_range.w2[0] | moment('DD MMM YYYY') }} -
                                    {{ penjualan.week_range.w2[1] | moment('DD MMM YYYY') }}
                                </small>
                            </th>
                            <th class="text-center f-14 font-600" colspan="2">
                                Week 3 <br>
                                <small>
                                    {{ penjualan.week_range.w3[0] | moment('DD MMM YYYY') }} -
                                    {{ penjualan.week_range.w3[1] | moment('DD MMM YYYY') }}
                                </small>
                            </th>
                            <th class="text-center f-14 font-600" colspan="2">
                                Week 4 <br>
                                <small>
                                    {{ penjualan.week_range.w4[0] | moment('DD MMM YYYY') }} -
                                    {{ penjualan.week_range.w4[1] | moment('DD MMM YYYY') }}
                                </small>
                            </th>
                        </tr>
                        <tr>
                            <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                            <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                            <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                            <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                            <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                            <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                            <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                            <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                        </tr>
                        <tbody>
                            <tr>
                                <td class="text-primary"><b>Leads</b></td>
                                <td class="text-center">{{ penjualan.leads_week_1 }}</td>
                                <td class="text-center" :class="penjualan.real_leads_week_1 < penjualan.leads_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_1 }}</td>
                                <td class="text-center">{{ penjualan.leads_week_2 }}</td>
                                <td class="text-center" :class="penjualan.real_leads_week_2 < penjualan.leads_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_2 }}</td>
                                <td class="text-center">{{ penjualan.leads_week_3 }}</td>
                                <td class="text-center" :class="penjualan.real_leads_week_3 < penjualan.leads_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_3 }}</td>
                                <td class="text-center">{{ penjualan.leads_week_4 }}</td>
                                <td class="text-center" :class="penjualan.real_leads_week_4 < penjualan.leads_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_4 }}</td>
                            </tr>
                            <tr>
                                <td class="text-primary"><b>Survey</b></td>
                                <td class="text-center">{{ penjualan.survey_week_1 }}</td>
                                <td class="text-center" :class="penjualan.real_survey_week_1 < penjualan.survey_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_1 }}</td>
                                <td class="text-center">{{ penjualan.survey_week_2 }}</td>
                                <td class="text-center" :class="penjualan.real_survey_week_2 < penjualan.survey_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_2 }}</td>
                                <td class="text-center">{{ penjualan.survey_week_3 }}</td>
                                <td class="text-center" :class="penjualan.real_survey_week_3 < penjualan.survey_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_3 }}</td>
                                <td class="text-center">{{ penjualan.survey_week_4 }}</td>
                                <td class="text-center" :class="penjualan.real_survey_week_4 < penjualan.survey_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_4 }}</td>
                            </tr>
                            <tr>
                                <td class="text-primary"><b>Booking</b></td>
                                <td class="text-center">{{ penjualan.booking_week_1 }}</td>
                                <td class="text-center" :class="penjualan.real_booking_week_1 < penjualan.booking_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_1 }}</td>
                                <td class="text-center">{{ penjualan.booking_week_2 }}</td>
                                <td class="text-center" :class="penjualan.real_booking_week_2 < penjualan.booking_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_2 }}</td>
                                <td class="text-center">{{ penjualan.booking_week_3 }}</td>
                                <td class="text-center" :class="penjualan.real_booking_week_3 < penjualan.booking_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_3 }}</td>
                                <td class="text-center">{{ penjualan.booking_week_4 }}</td>
                                <td class="text-center" :class="penjualan.real_booking_week_4 < penjualan.booking_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_4 }}</td>
                            </tr>
                            <tr>
                                <td class="text-primary"><b>Akad</b></td>
                                <td class="text-center">{{ penjualan.akad_week_1 }}</td>
                                <td class="text-center" :class="penjualan.real_akad_week_1 < penjualan.akad_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_1 }}</td>
                                <td class="text-center">{{ penjualan.akad_week_2 }}</td>
                                <td class="text-center" :class="penjualan.real_akad_week_2 < penjualan.akad_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_2 }}</td>
                                <td class="text-center">{{ penjualan.akad_week_3 }}</td>
                                <td class="text-center" :class="penjualan.real_akad_week_3 < penjualan.akad_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_3 }}</td>
                                <td class="text-center">{{ penjualan.akad_week_4 }}</td>
                                <td class="text-center" :class="penjualan.real_akad_week_4 < penjualan.akad_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_4 }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                ,
                <p class="f-24 font-700 text-black">Status Pembangunan</p>
                <ul class="nav nav-tabs mt-1" id="dbmyTab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="db-konstruksi-tab" data-toggle="tab" href="#db-konstruksi" role="tab" aria-controls="db-konstruksi" aria-selected="true">Konstruksi</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="db-infrastruktur-tab" data-toggle="tab" href="#db-infrastruktur" role="tab" aria-controls="db-infrastruktur" aria-selected="false">Infrastruktur</a>
                    </li>
                </ul>
                <div class="tab-content" id="dbmyTabContent">
                    <div class="tab-pane fade pt-1 show active" id="db-konstruksi" role="tabpanel" aria-labelledby="db-konstruksi-tab">
                        <div class="card bg-light mb-1" v-for="konstruksi in pembangunan.konstruksis" :key="konstruksi.id">
                            <div class="card-body pt-0">
                                <div class="badge badge-secondary mt-1">KONSTRUKSI</div> &nbsp;
                                <div class="badge mt-1 " :class="statusColor(konstruksi.status)">{{ konstruksi.status.toUpperCase() }}</div>
                                <div class="row mt-1">
                                    <div class="box-logo ml-1">PT</div>
                                    <div class="col align-self-center">
                                        <p class="mb-0 f-14">{{ konstruksi.name }}</p> 
                                        <p class="mb-0 f-14" v-if="konstruksi.deadline">{{ konstruksi.sisa_hari }} Hari Lagi Pada <span class="text-danger">{{ konstruksi.deadline | moment('DD MMM YYYY') }}</span></p>
                                    </div>
                                    <div class="col-2">
                                        <ProgressCircle :progress="konstruksi.progress_bobot"></ProgressCircle>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-center" v-if="pembangunan.konstruksis.length == 0">Tidak ada data</p>
                    </div>
                    <div class="tab-pane fade pt-1" id="db-infrastruktur" role="tabpanel" aria-labelledby="db-infrastruktur-tab">
                        <div class="card bg-light mb-1" v-for="infrastruktur in pembangunan.infrastrukturs" :key="infrastruktur.id">
                            <div class="card-body pt-0">
                                <div class="badge badge-secondary mt-1">INFRASTRUKTUR</div> &nbsp;
                                <div class="badge mt-1 " :class="statusColor(infrastruktur.status)">{{ infrastruktur.status.toUpperCase() }}</div>
                                <div class="row mt-1">
                                    <div class="box-logo ml-1">PT</div>
                                    <div class="col">
                                        <p class="mb-0 f-14">{{ infrastruktur.name }}</p> 
                                        <p class="mb-0 f-14">{{ infrastruktur.sisa_hari }} Hari Lagi Pada <span class="text-danger">{{ infrastruktur.deadline | moment('DD MMM YYYY') }}</span></p>
                                    </div>
                                    <div class="col-2">
                                        <ProgressCircle :progress="infrastruktur.progress_bobot"></ProgressCircle>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="text-center" v-if="pembangunan.infrastrukturs.length == 0">Tidak ada data</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import ProgressCircle from '@/components/ProgressCircle.vue'
export default {
    components: {
        ProgressCircle
    },
	data() {
		return {
            date_now: new Date(),
            perizinans: [],
            master_kas: [],
            penjualan: {
                akad_week_1: 0,
                akad_week_2: 0,
                akad_week_3: 0,
                akad_week_4: 0,
                booking_week_1: 0,
                booking_week_2: 0,
                booking_week_3: 0,
                booking_week_4: 0,
                leads_week_1: 0,
                leads_week_2: 0,
                leads_week_3: 0,
                leads_week_4: 0,
                real_akad_week_1: 0,
                real_akad_week_2: 0,
                real_akad_week_3: 0,
                real_akad_week_4: 0,
                real_booking_week_1: 0,
                real_booking_week_2: 0,
                real_booking_week_3: 0,
                real_booking_week_4: 0,
                real_leads_week_1: 0,
                real_leads_week_2: 0,
                real_leads_week_3: 0,
                real_leads_week_4: 0,
                real_survey_week_1: 0,
                real_survey_week_2: 0,
                real_survey_week_3: 0,
                real_survey_week_4: 0,
                survey_week_1: 0,
                survey_week_2: 0,
                survey_week_3: 0,
                survey_week_4: 0,
                week_range: {
                    w1: [],
                    w2: [],
                    w3: [],
                    w4: [],
                    w5: [],
                    w6: []
                }
            },
            pembangunan: {
                konstruksis: [],
                infrastrukturs: []
            }
		}
	},
    methods: {
        getDiffInDays(end_date)
        {
            let _ = this
            let start = _.$moment()
            let end = _.$moment(end_date)

            return end.diff(start, 'days')
        },
        get_master_kas() {
            let _ = this
            _.axios.get('/project/keuangan/master_kas?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.master_kas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        get_penjualan_by_period() {
            let _ = this
            _.axios.get('/project/penjualan/get_by_period?id_project=' + _.$route.params.id_project + '&period=' + _.$moment().format('YYYY-MM') + '-01' )
                .then(resp => {
                    _.penjualan = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 'Belum Mulai':
                    color = 'badge-light-secondary'
                    break;
                case 'Awas':
                    color = 'badge-light-danger'
                    break;
                case 'BAST':
                    color = 'badge-light-primary'
                    break;
                case 'Aman':
                    color = 'badge-light-success'
                    break;
                case 'Hati Hati':
                    color = 'badge-light-warning'
                    break;
            
                default:
                    break;
            }
            return color;
        }
    },
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.get_master_kas()
        _.get_penjualan_by_period()

        _.$root.$on('statusPerizinan', (data) => {
            _.perizinans = data
        })

        _.$root.$on('statusPembangunanKonstruksi', (data) => {
            _.pembangunan.konstruksis = data
            _.$forceUpdate()
        })

        _.$root.$on('statusPembangunanInfrastruktur', (data) => {
            _.pembangunan.infrastrukturs = data
            _.$forceUpdate()
        })
    },
}

</script>