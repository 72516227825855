<template>
    <section>
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="form-group input-group" v-if="!showAddPenjualan">
                    <span class="input-group-prepend">
                        <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                            <i class="fa fa-search"></i>
                        </div>
                    </span> 
                    <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search...">
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right">
                <button type="button" class="btn btn-blue" v-if="showAddPenjualan == false" @click="showAddPenjualan = true" v-can="'pm'">
                    Tambah Penjualan
                </button>
            </div>
        </div>
        <div v-if="showAddPenjualan">
            <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;">
                <div class="card-content box-shadow">
                    <div class="card-body pt-1 pb-1">
                        <div class="col pr-0">
                            <div class="row">
                                <img src="/assets/images/perizinan.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                                <div class="col text-left f-12 align-self-center">
                                    <input type="text" placeholder="Masukkan Nama Plan" class="border-0 w-50 f-16 font-600" v-model="model_penjualan.name">
                                </div>
                                <div class="col text-right">
                                    <date-picker
                                        v-model="model_penjualan.period"
                                        format="MMMM, YYYY"
                                        type="month"
                                        value-type="YYYY-MM-DD"
                                        placeholder="Pilih Bulan"
                                    ></date-picker>
                                </div>
                            </div>
                        </div>
                        <div class="col p-0">
                            <div class="table-responsive mt-1">
                                <table class="table">
                                    <tr>
                                        <th class="text-left f-14 font-600">Kriteria Performan</th>
                                        <th class="text-center f-14 font-600">Plan Week 1</th>
                                        <th class="text-center f-14 font-600">Plan Week 2</th>
                                        <th class="text-center f-14 font-600">Plan Week 3</th>
                                        <th class="text-center f-14 font-600">Plan Week 4</th>
                                    </tr>
                                    <tbody>
                                        <tr>
                                            <td class="text-primary"><b>Leads</b></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.leads_week_1"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.leads_week_2"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.leads_week_3"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.leads_week_4"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-primary"><b>Survey</b></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.survey_week_1"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.survey_week_2"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.survey_week_3"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.survey_week_4"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-primary"><b>Booking</b></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.booking_week_1"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.booking_week_2"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.booking_week_3"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.booking_week_4"></td>
                                        </tr>
                                        <tr>
                                            <td class="text-primary"><b>Akad</b></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.akad_week_1"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.akad_week_2"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.akad_week_3"></td>
                                            <td class="text-center"><input type="number" class="mx-auto form-control" placeholder="0" style="width: 50px;" required v-model="model_penjualan.akad_week_4"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="text-right">
                                <button class="btn text-danger" @click="penjualan_reset()">Cancel</button>
                                <button class="btn btn-blue" @click="penjualan_submit()">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ul class="nav nav-tabs" id="myTab" role="tablist" v-if="!showAddPenjualan">
            <li class="nav-item">
                <a class="nav-link active" id="approvedPenjualan-tab" data-toggle="tab" href="#approvedPenjualan" role="tab" aria-controls="approvedPenjualan" aria-selected="true">Plan yang telah disetujui</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" id="pendingPenjualan-tab" data-toggle="tab" href="#pendingPenjualan" role="tab" aria-controls="pendingPenjualan" aria-selected="false">Plan yang belum disetujui</a>
            </li>
        </ul>
        <div class="tab-content" id="myTabContentPenjualan" v-if="!showAddPenjualan">
            <div class="tab-pane fade pt-1 show active" id="approvedPenjualan" role="tabpanel" aria-labelledby="approvedPenjualan-tab">
                <div id="accordionPenjualan">
                    <div class="card" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;"
                        v-for="(penjualan, index_penjualan) in project.penjualans" :key="index_penjualan">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="col">
                                    <div class="row">
                                        <img src="/assets/images/perizinan.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                                        <div class="col text-left">
                                            <p class="f-16 font-600 mb-0">{{ penjualan.name }}</p>
                                            <p class="f-12 mb-0">
                                                <span class="text-primary">{{ penjualan.period | moment('MMMM Y')}}</span>
                                            </p>
                                        </div>
                                        <div>
                                            <button data-toggle="collapse" :data-target="'#collapsePenjualan_' + index_penjualan" aria-expanded="false" :aria-controls="'collapse_' + index_penjualan" class="btn pr-1 pl-0">
                                                <i class="f-20 fa fa-chevron-down"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div :id="'collapsePenjualan_' + index_penjualan" class="collapse row" data-parent="#accordionPenjualan">
                                    <div class="col">
                                        <div class="table-responsive mt-1">
                                            <table class="table">
                                                <tr>
                                                    <th class="text-center f-14 font-600" rowspan="2">Kriteria Performance</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 1</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 2</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 3</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 4</th>
                                                </tr>
                                                <tr>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                </tr>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-primary"><b>Leads</b></td>
                                                        <td class="text-center">{{ penjualan.leads_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_1 < penjualan.leads_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.leads_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_2 < penjualan.leads_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.leads_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_3 < penjualan.leads_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.leads_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_4 < penjualan.leads_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_4 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-primary"><b>Survey</b></td>
                                                        <td class="text-center">{{ penjualan.survey_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_1 < penjualan.survey_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.survey_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_2 < penjualan.survey_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.survey_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_3 < penjualan.survey_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.survey_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_4 < penjualan.survey_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_4 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-primary"><b>Booking</b></td>
                                                        <td class="text-center">{{ penjualan.booking_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_1 < penjualan.booking_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.booking_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_2 < penjualan.booking_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.booking_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_3 < penjualan.booking_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.booking_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_4 < penjualan.booking_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_4 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-primary"><b>Akad</b></td>
                                                        <td class="text-center">{{ penjualan.akad_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_1 < penjualan.akad_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.akad_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_2 < penjualan.akad_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.akad_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_3 < penjualan.akad_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.akad_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_4 < penjualan.akad_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_4 }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="project.penjualans && project.penjualans.length == 0" class="text-center">Tidak ada data</p>
                </div>
            </div>
            <div class="tab-pane fade pt-1" id="pendingPenjualan" role="tabpanel" aria-labelledby="pendingPenjualan-tab">
                <div id="accordionPenjualanPending">
                    <div class="card" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;"
                        v-for="(penjualan, index_penjualan) in project.penjualans_not_approved" :key="index_penjualan">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="col">
                                    <div class="row">
                                        <img src="/assets/images/perizinan.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                                        <div class="col text-left">
                                            <p class="f-16 font-600 mb-0">{{ penjualan.name }}</p>
                                            <p class="f-12 mb-0">
                                                <span class="text-primary">{{ penjualan.period | moment('MMMM Y')}}</span>
                                            </p>
                                        </div>
                                        <div class="col text-right" v-can="'pm'">
                                            <button class="btn btn-danger" @click="destroy(penjualan)">Hapus</button> &nbsp;
                                            <button class="btn btn-warning" @click="model_penjualan = penjualan, showAddPenjualan = true">Edit</button>
                                        </div>
                                        <div class="col text-right" v-can="'company'" v-if="penjualan.status_approval == 'waiting'">
                                            <button class="btn btn-primary" @click="penjualan_status(penjualan.id, 'approved')">Approve</button> &nbsp;
                                            <button class="btn btn-danger" @click="penjualan_status(penjualan.id, 'rejected')">Reject</button> 
                                        </div>
                                        <div>
                                            <button data-toggle="collapse" :data-target="'#collapsePenjualan_' + index_penjualan" aria-expanded="false" :aria-controls="'collapse_' + index_penjualan" class="btn pr-1 pl-0">
                                                <i class="f-20 fa fa-chevron-down"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div :id="'collapsePenjualan_' + index_penjualan" class="collapse row" data-parent="#accordionPenjualan">
                                    <div class="col">
                                        <div class="table-responsive mt-1">
                                            <table class="table">
                                                <tr>
                                                    <th class="text-center f-14 font-600" rowspan="2">Kriteria Performance</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 1</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 2</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 3</th>
                                                    <th class="text-center f-14 font-600" colspan="2">Week 4</th>
                                                </tr>
                                                <tr>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                    <th class="text-center"><div class="badge badge-light-primary rounded f-14">Plan</div></th>
                                                    <th class="text-center"><div class="badge badge-light-success rounded f-14">Realisasi</div></th>
                                                </tr>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-primary"><b>Leads</b></td>
                                                        <td class="text-center">{{ penjualan.leads_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_1 < penjualan.leads_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.leads_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_2 < penjualan.leads_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.leads_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_3 < penjualan.leads_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.leads_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_leads_week_4 < penjualan.leads_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_leads_week_4 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-primary"><b>Survey</b></td>
                                                        <td class="text-center">{{ penjualan.survey_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_1 < penjualan.survey_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.survey_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_2 < penjualan.survey_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.survey_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_3 < penjualan.survey_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.survey_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_survey_week_4 < penjualan.survey_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_survey_week_4 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-primary"><b>Booking</b></td>
                                                        <td class="text-center">{{ penjualan.booking_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_1 < penjualan.booking_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.booking_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_2 < penjualan.booking_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.booking_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_3 < penjualan.booking_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.booking_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_booking_week_4 < penjualan.booking_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_booking_week_4 }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-primary"><b>Akad</b></td>
                                                        <td class="text-center">{{ penjualan.akad_week_1 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_1 < penjualan.akad_week_1 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_1 }}</td>
                                                        <td class="text-center">{{ penjualan.akad_week_2 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_2 < penjualan.akad_week_2 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_2 }}</td>
                                                        <td class="text-center">{{ penjualan.akad_week_3 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_3 < penjualan.akad_week_3 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_3 }}</td>
                                                        <td class="text-center">{{ penjualan.akad_week_4 }}</td>
                                                        <td class="text-center" :class="penjualan.real_akad_week_4 < penjualan.akad_week_4 ? ' text-danger': ' text-success'">{{ penjualan.real_akad_week_4 }}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p v-if="project.penjualans_not_approved && project.penjualans_not_approved.length == 0" class="text-center">Tidak ada data</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            project: {
                penjualans: [],
                penjualans_not_approved: []
            },
            showAddPenjualan: false,
            model_penjualan: {
                id_project: '',
                name: '',
                period: '',
            }
        }
    },
    methods: {
        penjualan_get(){
            let _ = this
            _.axios.get('/project/penjualan?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.project.penjualans = resp.data.data
                    _.$forceUpdate()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        penjualan_get_not_approved(){
            let _ = this
            _.axios.get('/project/penjualan?id_project=' + _.$route.params.id_project + '&status_approval=not_approved' )
                .then(resp => {
                    _.project.penjualans_not_approved = resp.data.data
                    _.$forceUpdate()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        penjualan_reset(){
            let _ = this
            _.showAddPenjualan = false
            _.model_penjualan = {
                name: '',
                month: '',
                year: ''
            }
        },
        penjualan_submit(){
            let _ = this
            if (_.model_penjualan.id) {
                _.axios.put('/project/penjualan/' + _.model_penjualan.id, _.model_penjualan)
                    .then(resp => {
                        console.log(resp.data)
                        _.penjualan_reset()
                        _.penjualan_get_not_approved()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                _.model_penjualan.id_project = _.$route.params.id_project
                _.axios.post('/project/penjualan', _.model_penjualan)
                    .then(resp => {
                        console.log(resp.data)
                        _.penjualan_reset()
                        _.penjualan_get_not_approved()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Penjualan',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Penjualan <b>' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/penjualan/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.penjualan_get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Penjualan',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        penjualan_status(x, status) {
            let _ = this

            _.axios.post('/project/penjualan/' + x + '/' + status)
                .then( resp => {
                    console.log(resp.data)
                    _.penjualan_get()
                    _.penjualan_get_not_approved()
                })
        }
    },
    mounted() {
        let _ = this
        _.$moment.locale('id')
        _.penjualan_get()
        _.penjualan_get_not_approved()
    },
}
</script>