<template>
    <section>
        <div class="row">
            <div class="col-lg-4 col-xs-12">
                <div class="form-group input-group" v-if="!showQC && !showForm && !showHistoryComplain">
                    <span class="input-group-prepend">
                        <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                            <i class="fa fa-search"></i>
                        </div>
                    </span> 
                    <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search...">
                </div>
            </div>
            <div class="col-lg-8 col-xs-12 text-right">
                <button type="button" class="btn btn-primary" @click="showHistoryComplain = true" v-if="!showQC && !showHistoryComplain && showForm == false">
                    History Complain
                </button>&nbsp;
                <button type="button" class="btn btn-outline-primary" @click="showQC = true" v-if="!showQC && !showHistoryComplain && showForm == false" v-can="'pm'">
                    Quality Check
                </button>&nbsp;
                <button type="button" class="btn btn-blue" @click="showForm = true" v-if="showForm == false && !showQC && !showHistoryComplain" v-can="'pm'">
                    Tambah Konstruksi
                </button>
            </div>
        </div>
        <div v-if="showForm">
            <div class="col p-0">
                <h4 class="font-600">{{ model.id ? 'Edit' : 'Tambah' }} Konstruksi</h4>
                <div class="row">
                    <div class="form-group col-6">
                        <label for="">Nama Pekerjaan</label>
                        <input type="text" class="form-control" v-model="model.name" required placeholder="Masukkan Nama Pekerjaan">
                    </div>
                    <div class="form-group col-6">
                        <label for="">Luas Tanah</label>
                        <vue-numeric class="form-control" separator="." v-model="model.luas_tanah" placeholder="Masukkan Luas Tanah"></vue-numeric>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Luas Bangunan</label>
                        <vue-numeric class="form-control" separator="." currency="Rp" v-model="model.luas_bangunan" placeholder="Masukkan Luas Bangunan"></vue-numeric>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Tipe Bangunan</label>
                        <input type="text" class="form-control" v-model="model.type" required placeholder="Masukkan Tipe Bangunan">
                    </div>
                    <div class="form-group col-6">
                        <label for="">Harga Permeter Bangunan</label>
                        <vue-numeric class="form-control" separator="." currency="Rp" v-model="model.harga_permeter_bangunan" placeholder="Masukkan Harga Permeter Bangunan"></vue-numeric>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Harga Kontrak</label>
                        <vue-numeric class="form-control" separator="." currency="Rp" v-model="model.price" placeholder="Masukkan Harga Kontrak"></vue-numeric>
                    </div>
                    <div class="form-group col-6">
                        <label for="">Waktu Pengerjaan (Hari)</label>
                        <vue-numeric class="form-control" separator="." v-model="model.waktu_pengerjaan" placeholder="Masukkan Waktu Pengerjaan (Hari)"></vue-numeric>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button class="btn text-danger" @click="reset(), showForm = false">Cancel</button>&nbsp;
                <button class="btn btn-blue" @click="save()">
                    Submit
                </button>
            </div>
            <hr>
        </div>
        <div v-if="showHistoryComplain && !showForm">
            <HistoryComplain :konstruksis="konstruksis"></HistoryComplain>    
        </div>
        <div v-if="!showQC && !showHistoryComplain && !showForm">
            <ul class="nav nav-tabs" id="myTabApproval" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="approvedKonstruksi-tab" data-toggle="tab" href="#approvedKonstruksi" role="tab" aria-controls="approvedKonstruksi" aria-selected="true">Konstruksi yang telah disetujui</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="pendingKonstruksi-tab" data-toggle="tab" href="#pendingKonstruksi" role="tab" aria-controls="pendingKonstruksi" aria-selected="false">Konstruksi yang belum disetujui</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContentApproval">
                <div class="tab-pane fade pt-1 show active" id="approvedKonstruksi" role="tabpanel" aria-labelledby="approvedKonstruksi-tab">
                    <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;"
                        v-for="(konstruksi, index) in konstruksis" :key="index">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="mb-0 f-14 font-600">{{ konstruksi.name }}</p>
                                        <div class="row">
                                            <div class="progress mb-1 mt-1 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                <div class="progress-bar" role="progressbar" :style="'width: '+konstruksi.progress_bobot+'%;background: #3C8B43;'" :aria-valuenow="konstruksi.progress_bobot" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span class="align-self-center ml-1">{{ konstruksi.progress_bobot }}%</span>
                                        </div>
                                        <div class="row">
                                            <div class="col" v-if="konstruksi.deadline">{{ konstruksi.deadline | moment('DD MMM YYYY') }}</div>
                                            <div class="col" v-if="konstruksi.sisa_hari">Sisa Hari: <span class="text-primary font-600">{{ konstruksi.sisa_hari }}</span></div>
                                            <div class="col">Sisa Bobot: <span class="text-primary font-600">{{ konstruksi.sisa_bobot | numFormat }}</span></div>
                                        </div>
                                    </div>
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-warning btn-sm" v-can="'pm'" @click="model = konstruksi, showForm = true">Edit</button>&nbsp; -->
                                        <!-- <button class="btn btn-danger btn-sm" v-can="'pm'" @click="destroy(konstruksi)">Hapus</button>&nbsp; -->
                                        <button class="btn btn-primary btn-sm" @click="id_konstruksi = konstruksi.id, showQC = true">Hasil QC</button><br>

                                        <span class="badge mt-1 " :class="statusColor(konstruksi.status)">
                                            {{ konstruksi.status }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center" v-if="konstruksis && konstruksis.length == 0">Tidak ada data</p>
                </div>
                <div class="tab-pane fade pt-1" id="pendingKonstruksi" role="tabpanel" aria-labelledby="pendingKonstruksi-tab">
                    <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;"
                        v-for="(konstruksi, index) in konstruksis_not_approved" :key="index">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="mb-0 f-14 font-600">{{ konstruksi.name }}</p>
                                        <div class="row">
                                            <div class="progress mb-1 mt-1 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                <div class="progress-bar" role="progressbar" :style="'width: '+konstruksi.progress_bobot+'%;background: #3C8B43;'" :aria-valuenow="konstruksi.progress_bobot" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span class="align-self-center ml-1">{{ konstruksi.progress_bobot }}%</span>
                                        </div>
                                        <div class="row">
                                            <div class="col" v-if="konstruksi.deadline">{{ konstruksi.deadline | moment('DD MMM YYYY') }}</div>
                                            <div class="col" v-if="konstruksi.sisa_hari">Sisa Hari: <span class="text-primary font-600">{{ konstruksi.sisa_hari }}</span></div>
                                            <div class="col">Sisa Bobot: <span class="text-primary font-600">{{ konstruksi.sisa_bobot | numFormat }}</span></div>
                                        </div>
                                    </div>
                                    <div class="col-6 text-right" v-can="'company'" v-if="konstruksi.status_approval == 'waiting'">
                                        <button class="btn btn-primary btn-sm" @click="konstruksi_status(konstruksi.id, 'approved')">Approve</button> &nbsp;
                                        <button class="btn btn-danger btn-sm" @click="konstruksi_status(konstruksi.id, 'rejected')">Reject</button> 
                                    </div>
                                    <div class="col-6 text-right" v-can="'pm'" v-if="konstruksi.status_approval == 'waiting'">
                                        <button class="btn btn-warning btn-sm" @click="model = konstruksi, showForm = true">Edit</button>&nbsp;
                                        <button class="btn btn-danger btn-sm" @click="destroy(konstruksi)">Hapus</button><br>
                                        <!-- <button class="btn btn-primary btn-sm" @click="id_konstruksi = konstruksi.id, showQC = true">Hasil QC</button><br> -->

                                        <span class="badge mt-1 " :class="statusColor(konstruksi.status)">
                                            {{ konstruksi.status }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center" v-if="konstruksis_not_approved && konstruksis_not_approved.length == 0">Tidak ada data</p>
                </div>
            </div>
        </div>
        <div v-else-if="!showHistoryComplain && showForm == false">
            <QualityCheckKonstruksi :id_konstruksi="id_konstruksi"></QualityCheckKonstruksi>
        </div>
    </section>
</template>
<script>

import QualityCheckKonstruksi from '@/views/project/detail/pembangunan/konstruksi/QualityCheckIndex.vue'
import HistoryComplain from '@/views/project/detail/pembangunan/konstruksi/IndexHistoryComplain.vue'

export default {
    components: {
        QualityCheckKonstruksi,
        HistoryComplain
    },
    data() {
        return {
            konstruksis: [],
            konstruksis_not_approved: [],
            model: {
                id_project: null,
                luas_tanah: '',
                price: '',
            },
            showForm: false,
            showQC: false,
            showHistoryComplain: false,
            id_konstruksi: null
        }
    },
    mounted() {
        let _ = this
        _.get()
        _.get_not_approved()

        _.$root.$on('showQC', (data) => {
            _.showQC = data
            _.id_konstruksi = null
        })

        _.$root.$on('showHistoryComplain', (data) => {
            _.showHistoryComplain = data
        })
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('project/konstruksi?id_project=' + _.$route.params.id_project)
                .then(resp => {
                    _.konstruksis = resp.data.data
                    _.$root.$emit('statusPembangunanKonstruksi', _.konstruksis)
                })
        },
        get_not_approved() {
            let _ = this
            _.axios.get('project/konstruksi?id_project=' + _.$route.params.id_project + '&status_approval=not_approved' )
                .then(resp => {
                    _.konstruksis_not_approved = resp.data.data
                })
        },
        save() {
            let _ = this
            if (_.model.id) {
                _.axios.put('project/konstruksi/' + _.model.id, _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.reset()
                        _.showForm = false
                        _.get_not_approved()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                _.model.id_project = _.$route.params.id_project
                _.axios.post('project/konstruksi', _.model)
                    .then(resp => {
                        _.reset()
                        _.showForm = false
                        _.konstruksis_not_approved.push(resp.data.data)
                    })
            }
        },
        reset() {
            let _ = this
            _.model = {
                id_project: _.$route.params.id_project 
            }
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 'Belum Mulai':
                    color = 'badge-light-secondary'
                    break;
                case 'Awas':
                    color = 'badge-light-danger'
                    break;
                case 'BAST':
                    color = 'badge-light-primary'
                    break;
                case 'Aman':
                    color = 'badge-light-success'
                    break;
                case 'Hati Hati':
                    color = 'badge-light-warning'
                    break;
            
                default:
                    break;
            }
            return color;
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Konstruksi',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Konstruksi <b>' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/konstruksi/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Konstruksi',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        konstruksi_status(x, status) {
            let _ = this

            _.axios.post('/project/konstruksi/' + x + '/' + status)
                .then( resp => {
                    console.log(resp.data)
                    _.get()
                    _.get_not_approved()
                })
        }
    }
}
</script>