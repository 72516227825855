<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Simulasi Awal Proyek
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-2"></div>
            <div class="col-2 font-600 f-18 text-center align-self-center">Rencana <br>Awal*</div>
            <div class="col-2 font-600 f-18 text-center align-self-center">Realisasi</div>
            <div class="col-2 font-600 f-18 text-center align-self-center">Sisa <br>Budget</div>
            <div class="col-2 font-600 f-18 text-center align-self-center">Rencana <br>Update*</div>
            <div class="col-2 font-600 f-18 text-center align-self-center">Total</div>
        </div>

        <div v-for="(parent, index) in model.parent" :key="index">
            <p class="font-600 f-18 mb-0">{{ parent.name | capitalize }}</p>
            <div class="row" v-for="(children, index_children) in parent.children" :key="index_children">
                <div class="col-2 pl-4 align-self-center">
                    {{ children.name }}
                </div>
                <div class="col-2 text-center align-self-center">
                    <vue-numeric class="mx-auto text-center" separator="." v-model="children.rencana_awal" 
                        placeholder="Rp 0" style="border: 0px; box-shadow: none;" currency="Rp" 
                        @change="children.sisa_budget = children.rencana_awal - children.realisasi"></vue-numeric>
                </div>
                <div class="col-2 text-center align-self-center">
                    {{ children.realisasi | currency }}
                </div>
                <div class="col-2 text-center align-self-center">
                    {{ children.sisa_budget | currency }}
                </div>
                <div class="col-2 text-center align-self-center">
                    <vue-numeric class="mx-auto text-center" separator="." v-model="children.rencana_update" 
                        placeholder="Rp 0" style="border: 0px; box-shadow: none;" currency="Rp" 
                        @change="children.total = children.rencana_update + children.realisasi"></vue-numeric>
                </div>
                <div class="col-2 text-center align-self-center">
                    {{ children.total | currency }}
                </div>
            </div>
            <div class="row mb-1">
                <div class="col-2 font-600"><p class="mb-0">{{ parent.acumulation_name }}</p></div>
                <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate(parent.children, 'rencana_awal') | currency }}</p></div>
                <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate(parent.children, 'realisasi') | currency }}</p></div>
                <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate(parent.children, 'sisa_budget') | currency }}</p></div>
                <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate(parent.children, 'rencana_update') | currency }}</p></div>
                <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate(parent.children, 'total') | currency }}</p></div>
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-2 font-600"><p class="mb-0">Profit Akhir</p></div>
            <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate_all('rencana_awal') | currency }}</p></div>
            <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate_all('realisasi') | currency }}</p></div>
            <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate_all('sisa_budget') | currency }}</p></div>
            <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate_all('rencana_update') | currency }}</p></div>
            <div class="col-2 font-600 text-center"><p class="mb-0">{{ accumulate_all('total') | currency }}</p></div>
        </div>
        <hr>
        <div class="text-right">
            <button type="button" class="btn text-danger" @click="reset()">Reset</button>
            <button type="button" class="btn btn-blue" @click="save()">Simpan</button>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            model: {
                parent: []
            }
        }
    },
    methods: {
        accumulate(children, value){
            let total = 0;
            for (let i = 0; i < children.length; i++) {
                total = parseInt(total) + (parseInt(children[i][value]) ? parseInt(children[i][value]) : 0)
            }
            return parseInt(total);
        },
        accumulate_all(value){
            let total = 0;
            for (let i = 0; i < this.model.parent.length; i++) {
                if (i == 0) {
                    total += this.accumulate(this.model.parent[i].children, value)
                } else {
                    total -= this.accumulate(this.model.parent[i].children, value)
                }
            }
            return total;
        },
        reset(){
            for (let i = 0; i < this.model.parent.length; i++) {
                for (let y = 0; y < this.model.parent[i].children.length; y++) {
                    this.model.parent[i].children[y].rencana_awal = 0
                    this.model.parent[i].children[y].rencana_update = 0
                    this.model.parent[i].children[y].sisa_budget = 0
                    this.model.parent[i].children[y].total = 0
                }
            }
        },
        save() {
            let _ = this
            let data = {
                parent: JSON.stringify(_.model.parent)
            }
            _.axios.put('/project/keuangan/master_akun/simulasi', data)
                .then(resp => {
                    console.log(resp.data)
                    _.get_master_akun()
                })
                .catch(err => {
                    console.log(err)
                })
        },
        get_master_akun() {
            let _ = this
            _.axios.get('/project/keuangan/master_akun?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.model.parent = []
                    let data = resp.data.data.filter(x=>x.kriteria == 'nominal')
                    let categories = [
                        {
                            name: 'pendapatan',
                            acumulation_name: 'Total Pendapatan'
                        },
                        {
                            name: 'hpp',
                            acumulation_name: 'Total HPP'
                        },
                        {
                            name: 'beban_project',
                            acumulation_name: 'Total Beban Project'
                        },
                        {
                            name: 'beban_umum',
                            acumulation_name: 'Total Beban Umum'
                        },
                        {
                            name: 'beban_kantor',
                            acumulation_name: 'Total Beban Kantor'
                        },
                        {
                            name: 'beban_bunga',
                            acumulation_name: 'Total Beban Bunga'
                        },
                    ]

                    for (let i = 0; i < categories.length; i++) {
                        let children = []
                        for (let x = 0; x < data.length; x++) {
                            if (data[x].kategori == categories[i].name) {
                                children.push({ 
                                    id:              data[x].id,
                                    name:            data[x].name,
                                    rencana_awal:    data[x].rencana_awal,
                                    realisasi:       data[x].realisasi,
                                    rencana_update:  data[x].rencana_update,
                                    sisa_budget:     data[x].rencana_awal - data[x].realisasi,
                                    total:           data[x].realisasi + data[x].rencana_update
                                })
                            }
                        }
                        _.model.parent.push({
                            name:               categories[i].name,
                            acumulation_name:   categories[i].acumulation_name,
                            children:           children
                        })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
        },
    },
    mounted() {
        let _ = this
        _.get_master_akun()
    }
}
</script>