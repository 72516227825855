import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

import Home from '../views/Home.vue'
import HomeCompany from '../views/HomeCompany.vue'

import Undermaintenance from '../views/errors/503.vue'
import Forbidden from '../views/errors/403.vue'

import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import RegisterPM from '../views/auth/RegisterPM.vue'

import ApprovalPerusahaanIndex from '../views/approval_perusahaan/Index.vue'
import ApprovalPMIndex from '../views/approval_pm/Index.vue'

import ProjectIndex from '../views/project/Index.vue'
import ProjectDetailIndex from '../views/project/detail/Index.vue'

import ProjectPMIndex from '../views/project/IndexPM.vue'

import StaffAdminIndex from '../views/staff_admin/Index.vue'

import NotifikasiIndex from '../views/notifikasi/Index.vue'
import NotifikasiForm from '../views/notifikasi/Form.vue'
import NotifikasiDetail from '../views/notifikasi/Detail.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '',
        component: () => import ('../layouts/Main.vue'),
        children: [
            {
                path: '/',
                name: 'Home',
                component: Home
            },
            {
                path: '/company/view-report',
                name: 'HomeCompany',
                component: HomeCompany
            },
            {
                path: '/approval_perusahaan',
                name: 'ApprovalPerusahaanIndex',
                component: ApprovalPerusahaanIndex
            },
            {
                path: '/approval_pm',
                name: 'ApprovalPMIndex',
                component: ApprovalPMIndex
            },
            {
                path: '/:id_company/project',
                name: 'ProjectIndex',
                component: ProjectIndex
            },
            {
                path: '/:id_company/project/:id_project',
                name: 'ProjectDetailIndex',
                component: ProjectDetailIndex
            },
            {
                path: '/approval_plan',
                name: 'ApprovalPlanIndex',
                component: Undermaintenance
            },
            {
                path: '/notifikasi',
                name: 'NotifikasiIndex',
                component: NotifikasiIndex
            },
            {
                path: '/notifikasi/add',
                name: 'NotifikasiForm',
                component: NotifikasiForm
            },
            {
                path: '/notifikasi/:id',
                name: 'NotifikasiDetail',
                component: NotifikasiDetail
            },
            {
                path: '/dashboard',
                name: 'DashboardIndex',
                component: Undermaintenance
            },
            {
                path: '/project',
                name: 'ProjectPMIndex',
                component: ProjectPMIndex
            },
            {
                path: '/staff_admin',
                name: 'StaffAdminIndex',
                component: StaffAdminIndex
            },
            {
                path: '/403',
                name: 'Forbidden',
                component: Forbidden
            }
        ]
    },
    {
        path: '',
        component: () => import ('../layouts/FullPage.vue'),
        children: [
            {
                path: '/login',
                name: 'Login',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/login/:type',
                name: 'LoginUser',
                component: Login,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/register/company',
                name: 'Register',
                component: Register,
                meta: {
                    isGuest: true
                }
            },
            {
                path: '/register/pm',
                name: 'RegisterPM',
                component: RegisterPM,
                meta: {
                    isGuest: true
                }
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if ( to.meta.isGuest ) {
        next()
    } else if ( !store.state.token && !localStorage.getItem('token') ) {
        next({name: 'Login'})
    } else if (to.meta.roles){
        let isThere     = to.meta.roles.filter(x => x == localStorage.getItem('role'))
        let isAllowed   = false
        
        if (isThere.length > 0) {
            isAllowed = true;
        }
        
        if (isAllowed){
            next()
        } else{
            next({name: 'Forbidden'})
        }
    } else {
        next()
    }
})

export default router
