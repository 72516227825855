<template>
    <div class="content-body">
        <div class="mb-2 text-primary">
            <!-- <router-link to="/"> -->
                <i class="material-icons">people</i>
                <span class="menu-title align-self-center ml-1" style="vertical-align: super;"> Staff Admin</span>
            <!-- </router-link> -->
        </div>
        
        <div class="card w-100 ">
            <div class="card-content">
                <div class="card-body ic-statistic">
                    <div class="col mb-1">
                        <div class="row">
                            <img src="/assets/images/logo-approval-pm-circle.png" alt="" class="img-fluid mr-1" width="42">
                            <p class="f-20 mb-0 align-self-center">Staff Admin</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group input-group" v-if="!showAddForm">
                                <span class="input-group-prepend">
                                    <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span> 
                                <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search by Name" v-model="search">
                            </div>
                        </div>
                        <div class="col text-right">
                            <button class="btn btn-blue" @click="showAddForm = true, reset_form()" v-if="showAddForm == false">Tambah Staff Admin</button>
                        </div>
                    </div>
                    <form v-if="showAddForm == true">
                        <div class="form-group col-lg-6 p-0">
                            <label for="">Nama</label>
                            <input type="text" class="form-control" required v-model="form.name">
                        </div>
                        <div class="form-group col-lg-6 p-0">
                            <label for="">Email</label>
                            <input type="email" class="form-control" required v-model="form.email">
                        </div>
                        <div class="form-group col-lg-6 p-0" v-if="!form.id">
                            <label for="">Password</label>
                            <input type="password" class="form-control" required v-model="form.password">
                        </div>
                        <div class="col">
                            <div class="row mb-1">
                                <input type="checkbox" class="mr-1 align-self-center" 
                                    @click="checkRow('perizinan', $event)" :checked="form.access.some((obj)=> obj == 'perizinan')">
                                <label class="m-0">Perizinan</label>
                            </div>
                            <div class="row mb-1">
                                <input type="checkbox" class="mr-1 align-self-center"
                                    @click="checkRow('pembangunan', $event)" :checked="form.access.some((obj)=> obj == 'pembangunan')">
                                <label class="m-0">Pembangunan</label>
                            </div>
                            <div class="row mb-1">
                                <input type="checkbox" class="mr-1 align-self-center"
                                    @click="checkRow('penjualan', $event)" :checked="form.access.some((obj)=> obj == 'penjualan')">
                                <label class="m-0">Penjualan</label>
                            </div>
                            <div class="row mb-1">
                                <input type="checkbox" class="mr-1 align-self-center"
                                    @click="checkRow('keuangan', $event)" :checked="form.access.some((obj)=> obj == 'keuangan')">
                                <label class="m-0">Keuangan</label>
                            </div>
                        </div>
                        <hr>
                        <button type="submit" class="btn btn-blue pull-right mb-1" @click="showAddForm = false, save()">Simpan</button>
                        <button class="btn text-danger pull-right" @click="showAddForm = false">Batal</button>
                    </form>
                    <div class="table-responsive" v-if="!showAddForm">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th rowspan="2">Nama</th>
                                    <th rowspan="2">Email</th>
                                    <th colspan="4" class="text-center">Access</th>
                                    <th rowspan="2">Aksi</th>
                                </tr>
                                <tr>
                                    <th>Perizinan</th>
                                    <th>Pembangunan</th>
                                    <th>Penjualan</th>
                                    <th>Keuangan</th>
                                </tr>
                            </thead>
                            <tbody v-if="model.length > 0">
                                <tr v-for="(data, index) in model" :key="index">
                                    <td>{{data.user.name}}</td>
                                    <td>{{data.user.email}}</td>
                                    <td class="text-center">
                                        <input type="checkbox" class="mr-1 align-self-center" 
                                            v-if="data.access && data.access.some((obj)=> obj == 'perizinan')"
                                            :checked="1" disabled>
                                    </td>
                                    <td class="text-center">
                                        <input type="checkbox" class="mr-1 align-self-center" 
                                            v-if="data.access && data.access.some((obj)=> obj == 'pembangunan')"
                                            :checked="1" disabled>
                                    </td>
                                    <td class="text-center">
                                        <input type="checkbox" class="mr-1 align-self-center" 
                                            v-if="data.access && data.access.some((obj)=> obj == 'penjualan')"
                                            :checked="1" disabled>
                                    </td>
                                    <td class="text-center">
                                        <input type="checkbox" class="mr-1 align-self-center" 
                                            v-if="data.access && data.access.some((obj)=> obj == 'keuangan')"
                                            :checked="1" disabled>
                                    </td>
                                    <td>
                                        <button class="btn btn-warning btn-rounded" title="Edit" @click="showAddForm = true, edit(data)"><i class="fa fa-edit"></i></button>&nbsp;
                                        <button class="btn btn-danger btn-rounded" title="Hapus" @click="destroy(data)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="7">Tidak ada data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>           
    </div>
</template>

<script>
export default {
	data() {
		return {
			model: [],
            model_backup: [],
            search: '',
            showAddForm: false,
            form: {
                access: []
            }
		}
	},
    mounted() {
        let _ = this
        _.getList()
    },
    methods: {
        checkRow(value, event){
            let _ = this
            if (event.target.checked) {
                _.form.access.push(value)                
            } else {
                const index = _.form.access.indexOf(value);
                if (index > -1) {
                    _.form.access.splice(index, 1);
                }
            }
        },
        getList() {
            let _ = this
            _.axios.get('/staff_admin')
                .then(resp=>{
                    _.model = resp.data.data
                    _.model_backup = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Staff Admin',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Staff Admin <b>' + x.user.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/staff_admin/' + x.user.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete staff admin',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        save(){
            let _ =this
            _.form.access = JSON.stringify(_.form.access)

            if ( _.form.id ) {
                _.axios.put('staff_admin/' + _.form.id, _.form)
                    .then(resp=>{
                        console.log(resp.data)
                        _.form = {
                            access: []
                        }
                        _.getList()
                    })    
            } else {
                _.axios.post('staff_admin', _.form)
                    .then(resp=>{
                        console.log(resp.data)
                        _.form = {
                            access: []
                        }
                        _.getList()
                    })
            }
        },
        edit(data) {
            let _ = this
            _.form = data.user
            _.form.access = data.access
            _.showAddForm = true
        },
        reset_form(){
            this.form = {
                access: []
            }
        }
    },
    watch: {
        search: function() {
            let _ = this
            _.model = _.model_backup
            let searched = _.model.filter(function(data) {
                return data.user.name.toLowerCase().indexOf(_.search.toLowerCase()) > -1
            })
            _.model = searched
        }
    }
}
</script>
