<template>
    <div class="content-body">
        <div class="mb-2 text-primary">
            <!-- <router-link to="/"> -->
                <i class="material-icons">notifications</i>
                <span class="menu-title align-self-center ml-1" style="vertical-align: super;"> Notifikasi</span>
            <!-- </router-link> -->
        </div>
        
        <div class="card w-100 ">
            <div class="card-content">
                <div class="card-body ic-statistic">
                    <div class="col mb-1">
                        <div class="row">
                            <img src="/assets/images/logo-notification-circle.png" alt="" class="img-fluid mr-1" width="42">
                            <p class="f-20 mb-0 align-self-center">Notifikasi</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-xs-12">
                            <div class="form-group input-group">
                                <span class="input-group-prepend">
                                    <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                        <i class="fa fa-search"></i>
                                    </div>
                                </span> 
                                <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search by Title" v-model="search">
                            </div>
                        </div>
                        <div class="col text-right">
                            <button class="btn btn-blue" @click="$router.push('/notifikasi/add')">Tambah Notifikasi</button>
                        </div>
                    </div>
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Judul</th>
                                    <th>Deskripsi</th>
                                    <th>Foto</th>
                                    <th>Aksi</th>
                                </tr>
                            </thead>
                            <tbody v-if="model.length > 0">
                                <tr v-for="(data, index) in model" :key="index">
                                    <td>{{data.title}}</td>
                                    <td>{{ data.description.length > 100 ? data.description.substring(0, 100) + '...' : data.description}}</td>
                                    <td><img :src="data.file_image" class="img-fluid pointer" style="width: 100px;" @click="lihat(data.file_image)"> </td>
                                    <td style="width: 100px;">
                                        <button class="btn btn-info btn-rounded" title="Detail" @click="$router.push('/notifikasi/' + data.id)"><i class="fa fa-eye"></i></button>&nbsp;
                                        <button class="btn btn-danger btn-rounded" title="Hapus" @click="destroy(data)"><i class="fa fa-trash"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="4">Tidak ada data</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>           
    </div>
</template>

<script>
export default {
	data() {
		return {
			model: [],
            model_backup: [],
            search: ''
		}
	},
    mounted() {
        let _ = this
        _.getList()
    },
    methods: {
        getList() {
            let _ = this
            _.axios.get('/notification')
                .then(resp=>{
                    _.model = resp.data.data
                    _.model_backup = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Notifikasi',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Notifikasi <b>' + x.title + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/notification/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete notification',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        save(){
            let _ =this
            _.axios.post('notification', _.form)
                .then(resp=>{
                    console.log(resp.data)
                    _.getList()
                })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
    },
    watch: {
        search: function() {
            let _ = this
            _.model = _.model_backup
            let searched = _.model.filter(function(data) {
                return data.title.toLowerCase().indexOf(_.search.toLowerCase()) > -1
            })
            _.model = searched
        }
    }
}
</script>
