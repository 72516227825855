<template>
    <section>
        <div class="row">
            <div class="col-lg-6 col-xs-12">
                <div class="form-group input-group" v-if="!showQC && !showForm">
                    <span class="input-group-prepend">
                        <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                            <i class="fa fa-search"></i>
                        </div>
                    </span> 
                    <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search...">
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right">
                <button type="button" class="btn btn-outline-primary" @click="showQC = true" v-if="!showQC && !showForm" v-can="'pm'">
                    Quality Check
                </button>&nbsp;
                <button type="button" class="btn btn-blue" @click="showForm = true" v-if="showForm == false && !showQC" v-can="'pm'">
                    Tambah Infrastruktur
                </button>
            </div>
        </div>
        <div v-if="showForm">
            <div class="col-12 p-0">
                <h4 class="font-600">{{ model.id ? 'Edit' : 'Tambah' }} Infrastruktur</h4>
                <div class="row">
                    <div class="form-group col-4">
                        <label for="">Nama Pekerjaan</label>
                        <input type="text" class="form-control" v-model="model.name" required placeholder="Masukkan Nama Pekerjaan">
                    </div>
                    <div class="form-group col-4">
                        <label for="">Harga Kontrak</label>
                        <vue-numeric class="form-control" separator="." currency="Rp" v-model="model.price" placeholder="Masukkan Harga Kontrak"></vue-numeric>
                    </div>
                    <div class="form-group col-4">
                        <label for="">Deadline</label>
                        <date-picker
                            v-model="model.deadline"
                            format="DD/MM/YYYY"
                            type="date"
                            :input-class="'form-control'"
                            value-type="YYYY-MM-DD"
                            placeholder="Masukkan Deadline"
                            style="width: 100%;">
                        </date-picker>
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button class="btn text-danger" @click="reset(), showForm = false">Cancel</button>&nbsp;
                <button class="btn btn-blue" @click="save()">
                    Submit
                </button>
            </div>
            <hr>
        </div>
        <div class="" v-if="!showQC && !showForm">
            <ul class="nav nav-tabs" id="myTabApproval" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="approvedInfrastruktur-tab" data-toggle="tab" href="#approvedInfrastruktur" role="tab" aria-controls="approvedInfrastruktur" aria-selected="true">Infrastruktur yang telah disetujui</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="pendingInfrastruktur-tab" data-toggle="tab" href="#pendingInfrastruktur" role="tab" aria-controls="pendingInfrastruktur" aria-selected="false">Infrastruktur yang belum disetujui</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabInfrastruktur">
                <div class="tab-pane fade pt-1 show active" id="approvedInfrastruktur" role="tabpanel" aria-labelledby="approvedInfrastruktur-tab">
                    <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;"
                        v-for="(infrastruktur, index) in infrastrukturs" :key="index">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="mb-0 f-14 font-600">{{ infrastruktur.name }}</p>
                                        <div class="row">
                                            <div class="progress mb-1 mt-1 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                <div class="progress-bar" role="progressbar" :style="'width: '+infrastruktur.progress_bobot+'%;background: #3C8B43;'" :aria-valuenow="infrastruktur.progress_bobot" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span class="align-self-center ml-1">{{ infrastruktur.progress_bobot }}%</span>
                                        </div>
                                        <div class="row">
                                            <div class="col" v-if="infrastruktur.deadline">{{ infrastruktur.deadline | moment('DD MMM YYYY') }}</div>
                                            <div class="col" v-if="infrastruktur.sisa_hari">Sisa Hari: <span class="text-primary font-600">{{ infrastruktur.sisa_hari }}</span></div>
                                            <div class="col">Sisa Bobot: <span class="text-primary font-600">{{ infrastruktur.sisa_bobot | numFormat }}</span></div>
                                        </div>
                                    </div>
                                    <div class="col-6 text-right">
                                        <!-- <button class="btn btn-warning btn-sm" v-can="'pm'" @click="model = infrastruktur, showForm = true">Edit</button>&nbsp;
                                        <button class="btn btn-danger btn-sm" v-can="'pm'" @click="destroy(infrastruktur)">Hapus</button>&nbsp; -->
                                        <button class="btn btn-primary btn-sm" @click="id_infrastruktur = infrastruktur.id, showQC = true">Hasil QC</button><br>

                                        <span class="badge mt-1 " :class="statusColor(infrastruktur.status)">
                                            {{ infrastruktur.status }}
                                        </span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center" v-if="infrastrukturs && infrastrukturs.length == 0">Tidak ada data</p>
                </div>
                <div class="tab-pane fade pt-1" id="pendingInfrastruktur" role="tabpanel" aria-labelledby="pendingInfrastruktur-tab">
                    <div class="card mb-1" style="box-shadow: 0px 5px 6px rgb(171 171 171 / 25%) !important;"
                        v-for="(infrastruktur, index) in infrastrukturs_not_approved" :key="index">
                        <div class="card-content box-shadow">
                            <div class="card-body pt-1 pb-1">
                                <div class="row">
                                    <div class="col-6">
                                        <p class="mb-0 f-14 font-600">{{ infrastruktur.name }}</p>
                                        <div class="row">
                                            <div class="progress mb-1 mt-1 ml-1 align-self-center" style="width: 50%; height: 8px">
                                                <div class="progress-bar" role="progressbar" :style="'width: '+infrastruktur.progress_bobot+'%;background: #3C8B43;'" :aria-valuenow="infrastruktur.progress_bobot" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <span class="align-self-center ml-1">{{ infrastruktur.progress_bobot }}%</span>
                                        </div>
                                        <div class="row">
                                            <div class="col" v-if="infrastruktur.deadline">{{ infrastruktur.deadline | moment('DD MMM YYYY') }}</div>
                                            <div class="col" v-if="infrastruktur.sisa_hari">Sisa Hari: <span class="text-primary font-600">{{ infrastruktur.sisa_hari }}</span></div>
                                            <div class="col">Sisa Bobot: <span class="text-primary font-600">{{ infrastruktur.sisa_bobot | numFormat }}</span></div>
                                        </div>
                                    </div>
                                    <div class="col-6 text-right" v-can="'company'" v-if="infrastruktur.status_approval == 'waiting'">
                                        <button class="btn btn-primary btn-sm" @click="infrastruktur_status(infrastruktur.id, 'approved')">Approve</button> &nbsp;
                                        <button class="btn btn-danger btn-sm" @click="infrastruktur_status(infrastruktur.id, 'rejected')">Reject</button> 
                                    </div>
                                    <div class="col-6 text-right" v-can="'pm'" v-if="infrastruktur.status_approval == 'waiting'">
                                        <button class="btn btn-warning btn-sm" v-can="'pm'" @click="model = infrastruktur, showForm = true">Edit</button>&nbsp;
                                        <button class="btn btn-danger btn-sm" v-can="'pm'" @click="destroy(infrastruktur)">Hapus</button><br>
                                        <!-- <button class="btn btn-primary btn-sm" @click="id_infrastruktur = infrastruktur.id, showQC = true">Hasil QC</button><br> -->

                                        <span class="badge mt-1 " :class="statusColor(infrastruktur.status)">
                                            {{ infrastruktur.status }}
                                        </span>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <p class="text-center" v-if="infrastrukturs_not_approved && infrastrukturs_not_approved.length == 0">Tidak ada data</p>
                </div>
            </div>
        </div>
        <div v-else-if="showQC">
            <QualityCheckInfrastruktur :id_infrastruktur="id_infrastruktur"></QualityCheckInfrastruktur>
        </div>
    </section>
</template>
<script>
import QualityCheckInfrastruktur from '@/views/project/detail/pembangunan/infrastruktur/QualityCheckIndex.vue'

export default {
    components: {
        QualityCheckInfrastruktur
    },
    data() {
        return {
            infrastrukturs: [],
            infrastrukturs_not_approved: [],
            model: {
                id_project: null
            },
            showForm: false,
            showQC: false,
            id_infrastruktur: null
        }
    },
    mounted() {
        let _ = this
        _.get()
        _.get_not_approved()

        _.$root.$on('showQCInfra', (data) => {
            _.showQC = data
            _.id_infrastruktur = null
        })
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('project/infrastruktur?id_project=' + _.$route.params.id_project)
                .then(resp => {
                    _.infrastrukturs = resp.data.data
                    _.$root.$emit('statusPembangunanInfrastruktur', _.infrastrukturs)
                })
        },
        get_not_approved() {
            let _ = this
            _.axios.get('project/infrastruktur?id_project=' + _.$route.params.id_project + '&status_approval=not_approved' )
                .then(resp => {
                    _.infrastrukturs_not_approved = resp.data.data
                })
        },
        save() {
            let _ = this
            if (_.model.id) {
                _.axios.put('project/infrastruktur/' + _.model.id, _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.reset()
                        _.showForm = false
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                _.model.id_project = _.$route.params.id_project
                _.axios.post('project/infrastruktur', _.model)
                    .then(resp => {
                        _.reset()
                        _.showForm = false
                        _.infrastrukturs.push(resp.data.data)
                    })
            }
        },
        reset() {
            let _ = this
            _.model = {
                id_project: _.$route.params.id_project 
            }
        },
        statusColor(x) {
            let color = ''
            switch (x) {
                case 'Belum Mulai':
                    color = 'badge-light-secondary'
                    break;
                case 'Awas':
                    color = 'badge-light-danger'
                    break;
                case 'BAST':
                    color = 'badge-light-primary'
                    break;
                case 'Aman':
                    color = 'badge-light-success'
                    break;
                case 'Hati Hati':
                    color = 'badge-light-warning'
                    break;
            
                default:
                    break;
            }
            return color;
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Infrastruktur',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Infrastruktur <b>' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/infrastruktur/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Infrastruktur',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        infrastruktur_status(x, status) {
            let _ = this

            _.axios.post('/project/infrastruktur/' + x + '/' + status)
                .then( resp => {
                    console.log(resp.data)
                    _.get()
                    _.get_not_approved()
                })
        }
    }
}
</script>