<template>
    <div class="">
        <div class="col">
            <div class="row mb-1">
                <img src="/assets/img/icon/icon-company.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                <div class="col align-self-center">
                    <p class="font-600 f-20 mb-0">Quality Check</p>
                </div>
                <div class="col-lg-6 col-xs-12 text-right p-0">
                </div>
            </div>
        </div>
        <div class="mb-1" v-for="(data, index) in model" :key="index">
            <input type="text" class="f-20 font-600 mb-1" style="border: 0px" v-model="data.title" placeholder="Masukkan Judul Form">
            <button class="btn btn-sm btn-danger pull-right ml-1 d-lg-block d-none" @click="remove_form(index)" v-if="model.length > 1 && id_konstruksi == null">Hapus Form</button>
            <button class="btn btn-sm btn-blue pull-right d-lg-block d-none" @click="add_form(index)" v-if="index == model.length-1 && id_konstruksi == null">Tambah Form</button>
            <div class="row">
                <div class="col-lg-6">
                    <div v-for="(checkbox, index_checkbox) in data.checkboxes" :key="index_checkbox">
                        <div class="row mb-1 pl-1">
                            <input type="checkbox" name="" id="" disabled class="align-self-center mr-1" :checked="checkbox.value == 1">
                            <input type="text" name="" id="" required class="f-16 align-self-center col" 
                                v-model="checkbox.name" style="border: 0px;" 
                                placeholder="Masukkan Judul Checkbox">
                            <span class="text-danger align-self-center mr-1 pointer" 
                                v-if="data.checkboxes.length > 1 && id_konstruksi == null"
                                @click="remove_checkbox(index, index_checkbox)">
                                <i class="fa fa-trash"></i> Hapus
                            </span>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-outline-info mt-1 mb-1" @click="add_checkbox(index)" v-if="id_konstruksi == null">Tambah Checkbox</button>
                </div>
                <div class="col-lg-6">
                    <div class="form-group mb-0">
                        <label for="" class="f-16 font-500">Hasil Pengecekan</label>
                        <textarea name="" id="" cols="30" rows="5" class="form-control" disabled v-model="data.result"></textarea>
                    </div>
                    <div v-if="id_konstruksi != null && ( data.attachments && data.attachments.length > 0)">
                        <img :src="attachment" alt="" v-for="(attachment, index) in data.attachments" :key="index" 
                            class="rounded mr-1 mt-1" @click="lihat(attachment)" style="width: 50px; height:50px;">
                    </div>
                    <button class="btn btn-sm btn-danger pull-right ml-1 d-lg-none mt-1" @click="remove_form(index)" v-if="model.length > 1 && id_konstruksi == null">Hapus Form</button>
                    <button class="btn btn-sm btn-blue pull-right d-lg-none mt-1" @click="add_form(index)" v-if="index == model.length-1 && id_konstruksi == null">Tambah Form</button>
                </div>
            </div>
            <hr>
        </div>
        <div class="text-right" v-if="id_konstruksi == null">
            <button class="btn text-danger" @click="hideQC()">Cancel</button>
            <button class="btn btn-blue" @click="save()">Submit</button>
        </div>
        <div class="text-right" v-else>
            <button class="btn text-primary" @click="hideQC()">Kembali</button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['id_konstruksi'],
    data() {
        return {
            model: [
                {
                    title: "",
                    checkboxes: [
                        {
                            name: "",
                            value: ""
                        }
                    ],
                    result: ""
                }
            ]
        }
    },
    methods: {
        hideQC()
        {
            this.$root.$emit('showQC', false)
        },
        add_checkbox( id )
        {
            this.model[id].checkboxes.push({
                name: "",
                value: ""
            })
        },
        remove_checkbox( id, id_checkbox )
        {
            this.model[id].checkboxes.splice(id_checkbox, 1)
        },
        remove_form( id )
        {
            this.model.splice(id, 1)
        },
        add_form()
        {
            this.model.push({
                title: "",
                checkboxes: [
                    {
                        name: "",
                        value: ""
                    }
                ],
                result: ""
            })
        },
        save()
        {
            let _ = this
            let FData = new FormData()
            FData.append('id_project', this.$route.params.id_project)
            FData.append('raw', JSON.stringify(_.model))

            _.axios.post('/project/konstruksi/qc', FData)
                .then(resp => {
                    console.log(resp.data)
                    _.hideQC()
                })
        },
        get()
        {
            let _ = this
            _.axios.get('/project/konstruksi/qc?id_project=' + this.$route.params.id_project)
                .then(resp => {
                    if (resp.data.data) {
                        _.model = resp.data.data.raw
                    }
                })
        },
        getLastResult()
        {
            let _ = this
            _.axios.get('/konstruksi/qc?id_konstruksi=' + _.id_konstruksi)
                .then(resp => {
                    if (resp.data.data) {
                        _.model = resp.data.data.raw
                    }
                })
        },
        lihat(x) {
            let _ = this
            _.$swal.fire({
                imageUrl: x,
                imageWidth: '100%',
                width: '50%',
                customClass: {
                    image: 'rounded'
                }
            })
        },
    },
    mounted() {
        let _ = this
        _.get()
        if (_.id_konstruksi != null)
        {
            _.getLastResult()
        }
    }
}
</script>