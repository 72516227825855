import { ValidationProvider, extend } from 'vee-validate';
import { 
  required, 
  max, 
  max_value,
  min_value,
  email,
  min,
  confirmed,
  regex
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} harus diisi'
});

extend('min', {
  ...min,
  params: ['length'],
  message: '{_field_} harus minimal {length} karakter'
});

extend('max', {
  ...max,
  params: ['length'],
  message: '{_field_} maksimal {length} karakter'
});

extend('max_value', {
  ...max_value,
  message: '{_field_} seharusnya {max} atau lebih sedikit'
});

extend('min_value', {
  ...min_value,
  message: '{_field_} seharusnya {min} atau lebih'
});

extend('email', {
  ...email,
  message: '{_field_} harus berupa Email'
});

extend('confirmed', {
  ...confirmed,
  message: 'Konfirmasi password tidak cocok'
});

extend('regex', {
  ...regex,
  message: '{_field_} tidak valid'
});

export default ValidationProvider