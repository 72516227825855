<template>
    <section>
        <section v-if="kontraktor == null" v-can="'pm'">
            <div class="col">
                <div class="row">
                    <img src="/assets/img/icon/icon-company.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                    <div class="col align-self-center">
                        <input type="text" name="" id="" class="form-control border-0 pl-0 pt-0 pb-0" placeholder="Masukkan Nama Kontraktor" v-model="model_kontraktor.name">
                    </div>
                    <div class="col-lg-6 col-xs-12 text-right p-0">
                        <div class="d-lg-none mt-1"></div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="col mt-2">
                <div class="row">
                    <div class="col-lg-4 col-xs-12">
                        <div class="row text-center">
                            <i class="fa fa-map-pin f-25 text-primary" style="width: 25px;"></i>
                            <div class="col text-left">
                                <p class="f-14 text-secondary mb-0">Alamat</p>
                                <textarea name="" id="" cols="30" rows="5" class="form-control border-0 pl-0 pt-0 pb-0" placeholder="Masukan Alamat Kontraktor" v-model="model_kontraktor.address"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="row text-center">
                            <i class="fa fa-line-chart f-25 text-primary" style="width: 25px;"></i>
                            <div class="col text-left">
                                <p class="f-14 text-secondary mb-0">SIUJK</p>
                                <input type="text" class="form-control border-0 pl-0 pt-0 pb-0" placeholder="Masukkan SIUJK" v-model="model_kontraktor.siujk">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="row text-center">
                            <i class="fa fa-line-chart f-25 text-primary" style="width: 25px;"></i>
                            <div class="col text-left">
                                <p class="f-14 text-secondary mb-0">Skema Kontrak</p>
                                <input type="text" name="" id="" class="form-control border-0 pl-0 pt-0 pb-0" placeholder="Masukkan Skema Kontrak" v-model="model_kontraktor.skema_kontrak">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="text-right" v-can="'pm'">
                <button class="btn text-danger" @click="kontraktor_reset()">Reset</button>&nbsp;
                <button class="btn btn-blue" @click="kontraktor_create()">
                    Submit
                </button>
            </div>
        </section>
        <section v-else>
            <div class="col">
                <div class="row">
                    <img src="/assets/img/icon/icon-company.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                    <div class="col align-self-center">
                        <p class="font-400 f-12 mb-0">Kontraktor</p>
                        <p class="font-600 f-16 mb-0">{{ kontraktor.name }}</p>
                    </div>
                    <div class="col-lg-6 col-xs-12 text-right p-0">
                        <div class="d-lg-none mt-1"></div>
                        <!-- <button class="btn btn-warning">
                            Edit
                        </button> -->
                    </div>
                </div>
            </div>
            <hr>
            <div class="col mt-2">
                <div class="row">
                    <div class="col-lg-4 col-xs-12">
                        <div class="row text-center">
                            <i class="fa fa-map-pin f-25 text-primary" style="width: 25px;"></i>
                            <div class="col text-left">
                                <p class="f-14 text-secondary mb-0">Alamat</p>
                                <p class="f-14 text-justify" style="line-height: 15px;">{{kontraktor.address}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="row text-center">
                            <i class="fa fa-line-chart f-25 text-primary" style="width: 25px;"></i>
                            <div class="col text-left">
                                <p class="f-14 text-secondary mb-0">SIUJK</p>
                                <p class="f-14 text-justify" style="line-height: 15px;">{{kontraktor.siujk}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-xs-12">
                        <div class="row text-center">
                            <i class="fa fa-line-chart f-25 text-primary" style="width: 25px;"></i>
                            <div class="col text-left">
                                <p class="f-14 text-secondary mb-0">Skema Kontrak</p>
                                <p class="f-14 text-justify" style="line-height: 15px;">{{kontraktor.skema_kontrak}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ul class="nav nav-tabs mt-1" id="myTab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active" id="konstruksi-tab" data-toggle="tab" href="#konstruksi" role="tab" aria-controls="konstruksi" aria-selected="true">Konstruksi</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="infrastruktur-tab" data-toggle="tab" href="#infrastruktur" role="tab" aria-controls="infrastruktur" aria-selected="false">Infrastruktur</a>
                </li>
            </ul>
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade pt-1 show active" id="konstruksi" role="tabpanel" aria-labelledby="konstruksi-tab">
                    <Konstruksi></Konstruksi>
                </div>
                <div class="tab-pane fade pt-1" id="infrastruktur" role="tabpanel" aria-labelledby="infrastruktur-tab">
                    <Infrastruktur></Infrastruktur>
                </div>
            </div>
        </section>
    </section>
</template>
<script>
import Konstruksi from '@/views/project/detail/pembangunan/konstruksi/Index.vue'
import Infrastruktur from '@/views/project/detail/pembangunan/infrastruktur/Index.vue'

export default {
    components: {
        Konstruksi,
        Infrastruktur
    },
    data() {
        return {
            kontraktor: null,
            model_kontraktor: {
                id_project: null
            }
        }
    },
    mounted() {
        let _ = this
        _.kontraktor_get()
    },
    methods: {
        kontraktor_get() {
            let _ = this
            _.axios.get('project/kontraktor?id_project=' + _.$route.params.id_project)
                .then(resp => {
                    _.kontraktor = resp.data.data
                })
        },
        kontraktor_create() {
            let _ = this
            _.model_kontraktor.id_project = _.$route.params.id_project
            _.axios.post('project/kontraktor', _.model_kontraktor)
                .then(resp => {
                    _.kontraktor = resp.data.data
                })
        },
        kontraktor_reset() {
            let _ = this
            _.model_kontraktor = {
                id_project: _.$route.params.id_project
            }
        }
    }
}
</script>