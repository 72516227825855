<template>
    <div class="bg-white" style="min-height: 100vh">
        <div class="col">
            <div class="row" style="height: 100%; min-height: 100vh;">
                <div class="col-xs-12 col-lg-6 p-lg-4 p-2">
                    <p style="font-size: 24px;"><span class="fa fa-arrow-left mr-1 f-18 pointer" @click="$router.back()"></span><b>Register</b></p>
                    <form @submit.prevent="register()" class="mt-3">
                        <ValidationObserver ref="observer" v-slot="{ invalid }">
                            <div class="row">
                                <div class="col">
                                    <ValidationProvider name="Nama" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Nama</label>
                                                <input type="text" class="form-control" v-model="model.name" placeholder="Masukkan Nama" 
                                                    style="border: 0px; border-bottom: 1px solid #ccc;">
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="NIK" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">NIK</label>
                                                <input type="text" class="form-control" v-model="model.nik" placeholder="Masukkan NIK" 
                                                    style="border: 0px; border-bottom: 1px solid #ccc;">
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="Luas Tanah" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Luas Tanah</label>
                                                <vue-numeric class="form-control" separator="." v-model="model.luas_tanah" placeholder="Masukkan Luas Tanah" style="border: 0px; border-bottom: 1px solid #ccc;"></vue-numeric>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="Nilai Proyek" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Nilai Proyek</label>
                                                <vue-numeric class="form-control" separator="." v-model="model.nilai" currency="Rp" placeholder="Masukkan Nilai Proyek" style="border: 0px; border-bottom: 1px solid #ccc;"></vue-numeric>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="Alamat" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Alamat</label>
                                                <textarea cols="30" rows="3" class="form-control" v-model="model.alamat"
                                                    style="border: 0px; border-bottom: 1px solid #ccc;" placeholder="Masukkan Alamat"></textarea>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col">
                                    <ValidationProvider name="Email" rules="required|email" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Email</label>
                                                <input type="text" class="form-control" v-model="model.email" placeholder="Masukkan Email" 
                                                    style="border: 0px; border-bottom: 1px solid #ccc;">
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="Nama Perusahaan" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Nama Perusahaan</label>
                                                <select class="form-control" style="border: 0px; border-bottom: 1px solid #ccc;" v-model="model.id_company">
                                                    <option value="">Pilih Perusahaan</option>
                                                    <option v-for="(company, index) in companies" :key="index" :value="company.id">
                                                        {{ company.user.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="Jumlah Unit" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Jumlah Unit</label>
                                                <vue-numeric class="form-control" separator="." v-model="model.jumlah_unit" placeholder="Masukkan Jumlah Unit" style="border: 0px; border-bottom: 1px solid #ccc;"></vue-numeric>
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                    <ValidationProvider name="Nama Proyek" rules="required" v-slot="{ errors }">
                                        <div class="col p-0">
                                            <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                                <label class="font-bold f-14">Nama Proyek</label>
                                                <input type="text" class="form-control" v-model="model.project_name" placeholder="Masukkan Nama Proyek" 
                                                    style="border: 0px; border-bottom: 1px solid #ccc;">
                                            </div>
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <ValidationProvider name="password" rules="required|min:6" v-slot="{ errors }">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label class="font-bold f-14">Password</label>
                                            <input type="password" v-model="model.password" class="form-control" placeholder="Masukkan Password"
                                                style="border: 0px; border-bottom: 1px solid #ccc;">
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col">
                                    <ValidationProvider name="Confirm Password" rules="required|confirmed:password" v-slot="{ errors }">
                                        <div class="form-group" :class="{ 'validation-warning': errors[0] }">
                                            <label class="font-bold f-14">Konfirmasi Password</label>
                                            <input type="password" v-model="model.confirm_password" class="form-control" placeholder="Konfirmasi Password"
                                                style="border: 0px; border-bottom: 1px solid #ccc;">
                                        </div>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary waves-effect waves-light w-100" :disabled="isLoading || invalid">
                                <div v-if="isLoading">Daftar <i class="fa fa-spinner fa-spin"></i></div>
                                <div v-else>Daftar</div>
                            </button>
                        </ValidationObserver>
                    </form>           
                </div>
                <div class="col-xs-12 col-lg-6 d-none d-lg-block p-0">
                    <div class="image-promit-signup"></div>
                    <div style="position: absolute; top: 30%; width: 100%;" class="d-none d-lg-block">
                        <p class="text-center"><img src="/assets/img/logo-login.png" alt=""></p>
                        <p class="text-center text-white" style="font-size: 42px; line-height: 2.5rem;"><b>Welcome to</b> Pro<b>MIT</b></p>
                        <p class="text-center text-white" style="font-size: 26px;">Start manage your projects</p>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
export default {
    data() {
        return {
            model: {
                id_company: "",
                luas_tanah: '',
                nilai: '',
                jumlah_unit: ''
            },
            companies: [],
            isLoading: false
        }
    },
	methods: {
		register() {
            let _ = this  
            _.isLoading = true

            _.axios.post( '/project_manager', _.model )
                .then(()=>{
                    _.$router.push('/login')
                })
                .catch(()=>{
                    _.authFalse = 1
                    _.isLoading = false
                })
		},
        getCompany(){
            let _ = this
            _.axios.get('/company?status=approved')
                .then(resp => {
                    _.companies = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        }
	},
    mounted() {
        let _ = this
        _.getCompany()
    }
}
</script>