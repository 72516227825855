<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Master Transaksi
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right" v-if="!showForm">
                <button type="button" class="btn btn-blue mb-1" @click="resetModel(), showForm = true">
                    Tambah Transaksi
                </button>
            </div>
        </div>
        <div class="row mt-1" v-if="showForm == true">
            <div class="col-lg-3">
                <div class="form-group">
                    <label for="">Tipe CF</label>
                    <select name="" id="" class="form-control" v-model="model.type_cf">
                        <option value="">Pilih Tipe CF</option>
                        <option value="fcf">FCF</option>
                        <option value="ocf">OCF</option>
                        <option value="icf">ICF</option>
                        <option value="bukan_cf">Bukan CF</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label for="">Nama Transaksi</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Transaksi" v-model="model.name">
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label for="">Akun Debit</label>
                    <select name="" id="" class="form-control" v-model="model.id_akun_debit">
                        <option value="">Pilih Akun Debit</option>
                        <option v-for="(data, index) in master_akuns" :key="index" :value="data.id">{{data.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <label for="">Akun Kredit</label>
                    <select name="" id="" class="form-control" v-model="model.id_akun_kredit">
                        <option value="">Pilih Akun Kredit</option>
                        <option v-for="(data, index) in master_akuns" :key="index" :value="data.id">{{data.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="form-group">
                    <div class="row">
                        <div class="col-1 align-self-center">
                            <input type="checkbox" v-model="model.is_pemilik_lahan">
                        </div>
                        <div class="col">
                            <label for="">Pemilik Lahan</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1 align-self-center">
                            <input type="checkbox" v-model="model.is_investor">
                        </div>
                        <div class="col">
                            <label for="">Investor</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 align-self-center text-right mb-1">
                <button type="button" class="btn text-danger" @click="showForm = false">Batal</button>
                <button type="button" class="btn btn-blue" @click="showForm = false, save()">Simpan</button>
            </div>
        </div>
        <div class="table-responsive" v-if="!showForm">
            <table class="table">
                <thead >
                    <tr>
                        <th style="background: #3D8D4629">Tipe CF</th>
                        <th style="background: #3D8D4629">Nama Transaksi</th>
                        <th style="background: #3D8D4629">Akun Debit</th>
                        <th style="background: #3D8D4629">Akun Kredit</th>
                        <th style="background: #3D8D4629">Pemilik Lahan</th>
                        <th style="background: #3D8D4629">Investor</th>
                        <th style="background: #3D8D4629">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="datas.length > 0">
                    <tr v-for="(data, index) in datas" :key="index">
                        <td>{{ data.type_cf.toUpperCase() }}</td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.akun_debit_name }}</td>
                        <td>{{ data.akun_kredit_name }}</td>
                        <td><input type="checkbox" name="" id="" v-model="data.is_pemilik_lahan" v-if="data.is_pemilik_lahan"></td>
                        <td><input type="checkbox" name="" id="" v-model="data.is_investor" v-if="data.is_investor"></td>
                        <td>
                            <button class="btn btn-rounded btn-warning" title="Edit" @click="model = data, showForm = true"><i class="fa fa-edit"></i></button>&nbsp;
                            <button class="btn btn-rounded btn-danger" title="Hapus" @click="destroy(data)"><i class="fa fa-trash"></i></button>&nbsp;
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7" class="text-center">Tidak ada data</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            showForm: false,
            datas: [],
            model: {
                id_project: this.$route.params.id_project,
                type_cf: '', 
                name: '', 
                id_akun_debit: '', 
                id_akun_kredit: '',
                is_pemilik_lahan: false,
                is_investor: false,
            },
            master_akuns: []
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/project/keuangan/master_transaksi?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.datas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        get_master_akun() {
            let _ = this
            _.axios.get('/project/keuangan/master_akun?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.master_akuns = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        save() {
            let _ = this
            if (_.model.id) {
                _.axios.put('/project/keuangan/master_transaksi/' + _.model.id, _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                _.axios.post('/project/keuangan/master_transaksi', _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Master Transaksi',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Master Transaksi <b>' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/keuangan/master_transaksi/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Master Transaksi',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        resetModel() {
            this.model = {
                id_project: this.$route.params.id_project,
                type_cf: '', 
                name: '', 
                id_akun_debit: '', 
                id_akun_kredit: '',
                is_pemilik_lahan: false,
                is_investor: false,
            }
        }
    },
    mounted() {
        let _ = this
        _.get()
        _.get_master_akun()
    }
}
</script>