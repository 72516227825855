<template>
    <div class="content-body">
        <div class="mb-2 text-primary">
            <router-link to="/notifikasi">
                <i class="material-icons">notifications</i>
                <span class="menu-title align-self-center ml-1" style="vertical-align: super;"> Notifikasi / Detail</span>
            </router-link>
        </div>
        
        <div class="card w-100 ">
            <div class="card-content">
                <div class="card-body ic-statistic">
                    <div class="col mb-1">
                        <div class="row">
                            <img src="/assets/img/icon/icon-view-report.png" alt="" class="img-fluid mr-1" width="42">
                            <p class="f-20 mb-0 align-self-center">{{model.title}}</p>
                        </div>
                    </div>
                    <hr>
                    <img :src="model.file_image" alt="" class="img-fluid rounded">
                    <div class="f-16 text-justify mt-1" v-html="model.description"></div>
                </div>
            </div>
        </div>           
    </div>
</template>

<script>
export default {
	data() {
		return {
			model: {}
		}
	},
    mounted() {
        let _ = this
        _.get()
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/notification/' + _.$route.params.id)
                .then(resp=>{
                    _.model = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        }
    }
}
</script>
