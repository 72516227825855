<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Master Akun <span class="badge badge-md badge-danger" v-if="!balance">Jumlah Saldo Debit & Kredit Tidak Seimbang</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xs-12 text-right" v-if="!showForm">
                <button type="button" class="btn btn-blue mb-1" @click="showForm = true">
                    Tambah Akun
                </button>
            </div>
        </div>
        <div class="row mt-1" v-if="showForm == true">
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Kategori</label>
                    <select name="" id="" class="form-control" v-model="model.kategori">
                        <option value="">Pilih Kategori</option>
                        <option value="aset">Aset</option>
                        <option value="kewajiban">Kewajiban</option>
                        <option value="modal">Modal</option>
                        <option value="pendapatan">Pendapatan</option>
                        <option value="hpp">HPP</option>
                        <option value="beban_project">Beban Project</option>
                        <option value="beban_umum">Beban Umum</option>
                        <option value="beban_kantor">Beban Kantor</option>
                        <option value="beban_bunga">Beban Bunga</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Kode</label>
                    <input type="text" class="form-control" placeholder="Masukkan Kode Akun" v-model="model.kode">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Nama Akun</label>
                    <input type="text" class="form-control" placeholder="Masukkan Nama Akun" v-model="model.name">
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Kriteria Akun</label>
                    <select name="" id="" class="form-control" v-model="model.kriteria">
                        <option value="">Pilih Kriteria Akun</option>
                        <option value="real">Real</option>
                        <option value="nominal">Nominal</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="form-group">
                    <label for="">Saldo Normal</label>
                    <select name="" id="" class="form-control" v-model="model.saldo_normal">
                        <option value="">Pilih Saldo Normal</option>
                        <option value="debit">Debit</option>
                        <option value="kredit">Kredit</option>
                    </select>
                </div>
            </div>
            <div class="col-lg-4">
                <label for="" class="f-14">Saldo Awal</label>
                <div class="form-group input-group">
                    <div class="input-group">
                        <div class="input-group-prepend">
                            <div class="input-group-text">Rp</div>
                        </div>
                        <vue-numeric class="form-control" separator="." v-model="model.saldo_awal" placeholder="Masukkan Saldo Awal"></vue-numeric>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 align-self-center text-right mb-1">
                <button type="button" class="btn text-danger" @click="showForm = false">Batal</button>
                <button type="button" class="btn btn-blue" @click="showForm = false, save()">Simpan</button>
            </div>
        </div>
        <div class="table-responsive" v-if="!showForm">
            <table class="table">
                <thead >
                    <tr>
                        <th style="background: #3D8D4629">Kategori</th>
                        <th style="background: #3D8D4629">Kode</th>
                        <th style="background: #3D8D4629">Nama Akun</th>
                        <th style="background: #3D8D4629">Kriteria Akun</th>
                        <th style="background: #3D8D4629">Saldo Normal</th>
                        <th style="background: #3D8D4629">Saldo Awal</th>
                        <th style="background: #3D8D4629">Aksi</th>
                    </tr>
                </thead>
                <tbody v-if="datas.length > 0">
                    <tr v-for="(data, index) in datas" :key="index">
                        <td>{{ data.kategori }}</td>
                        <td>{{ data.kode }}</td>
                        <td>{{ data.name }}</td>
                        <td>{{ data.kriteria }}</td>
                        <td>{{ data.saldo_normal }}</td>
                        <td>{{ data.saldo_awal | currency}}</td>
                        <td>
                            <button class="btn btn-rounded btn-warning" title="Edit" @click="model = data, showForm = true"  v-if="!data.is_default"><i class="fa fa-edit"></i></button>&nbsp;
                            <button class="btn btn-rounded btn-danger" title="Hapus" @click="destroy(data)" v-if="!data.is_default"><i class="fa fa-trash"></i></button>&nbsp;
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="7" class="text-center">Tidak ada data</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            showForm: false,
            datas: [],
            model: {
                id_project: this.$route.params.id_project,
                kategori: '',
                kode: '',
                name: '',
                kriteria: '',
                saldo_normal: ''
            }
        }
    },
    computed: {
        balance: function(){
            let _ = this
            let saldo_kredit = 0
            let saldo_debit = 0

            for (let i = 0; i < _.datas.length; i++) {
                if (_.datas[i].saldo_normal == 'kredit') {
                    saldo_kredit += _.datas[i].saldo_awal
                } else {
                    saldo_debit += _.datas[i].saldo_awal
                }
            }

            return saldo_kredit == saldo_debit;
        }
    },
    methods: {
        get() {
            let _ = this
            _.axios.get('/project/keuangan/master_akun?id_project=' + _.$route.params.id_project )
                .then(resp => {
                    _.datas = resp.data.data
                })
                .catch(err => {
                    console.log(err)
                })
        },
        save() {
            let _ = this

            if (_.model.id) {
                _.axios.put('/project/keuangan/master_akun/' + _.model.id, _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                _.axios.post('/project/keuangan/master_akun', _.model)
                    .then(resp => {
                        console.log(resp.data)
                        _.get()
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        },
        destroy(x) {
            let _ = this
            _.$swal.fire({
                title: 'Hapus Master Akun',
                html:'Apakah Anda yakin ingin <span class="text-danger">menghapus</span> Master Akun <b>' + x.kode + ' | ' + x.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                })
                .then((result) => {
                    if (result.value) {
                        _.axios.delete('/project/keuangan/master_akun/' + x.id)
                            .then(resp => {
                                if (resp.status) {
                                    _.get()
                                    _.$swal.fire({
                                        title: 'Deleted!',
                                        text: 'Successfully delete Master Akun',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
    },
    mounted() {
        let _ = this
        _.get()
    }
}
</script>