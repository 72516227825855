<template>
    <section>
        <div class="row mt-1">
            <div class="col-lg-6 col-xs-12">
                <div class="col">
                    <div class="row">
                        <img src="/assets/images/mas_akun.png" alt="" style="width:42px;height:42px;object-fit:none;background:rgba(61, 141, 70, 0.1);border-radius:50%;">
                        <div class="col text-left f-20 font-600 align-self-center">
                            Neraca
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <p class="text-center f-25 font-700">
            Laporan Neraca Keuangan Proyek<br>
            <date-picker
                v-model="year"
                format="YYYY"
                type="year"
                :input-class="'form-control mx-auto mt-1 f-20 text-left'"
                value-type="YYYY"
                placeholder="Pilih Tahun"
                style="width: 90px;">
            </date-picker>
        </p>
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th class="text-center bg-light f-14" style="width: 100px;">Kode</th>
                    <th class="text-center bg-light f-14" style="width: 50%;">Transaksi</th>
                    <th class="text-center bg-light f-14" colspan="2">Nominal</th>
                    <th class="text-center bg-light f-14">Persentase</th>
                </tr>
                <tr v-for="(data, index) in model.aset" :key="index">
                    <td class="text-center">{{ data.kode }}</td>
                    <td>{{ data.name }}</td>
                    <td>Rp</td>
                    <td class="text-right">{{ data.total | numFormat }}</td>
                    <td class="text-center">{{ data.total / total_aset * 100 | numFormat }}%</td>
                </tr>
                <tr>
                    <td colspan="2" class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Total Asset</td>
                    <td class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Rp</td>
                    <td class="text-right font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_aset | numFormat }}</td>
                    <td class="text-center font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_aset != 0 ? 100 : 0 }}%</td>
                </tr>
            </table>
        </div>
        <div class="table-responsive">
            <table class="table">
                <tr>
                    <th class="text-center bg-light f-14" style="width: 100px;">Kode</th>
                    <th class="text-center bg-light f-14" style="width: 50%;">Transaksi</th>
                    <th class="text-center bg-light f-14" colspan="2">Nominal</th>
                    <th class="text-center bg-light f-14">Persentase</th>
                </tr>
                <tr v-for="(data, index) in model.kewajiban" :key="index">
                    <td class="text-center">{{ data.kode }}</td>
                    <td>{{ data.name }}</td>
                    <td>Rp</td>
                    <td class="text-right">{{ data.total | numFormat }}</td>
                    <td class="text-center">{{ data.total / total_kewajiban * 100 | numFormat }}%</td>
                </tr>
                <tr>
                    <td></td>
                    <td>Laba Bersih</td>
                    <td>Rp</td>
                    <td class="text-right">{{ lababersih | numFormat }}</td>
                    <td class="text-center">{{ lababersih / total_kewajiban * 100 | numFormat }}%</td>
                </tr>
                <tr>
                    <td colspan="2" class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Total Kewajiban & Modal Awal</td>
                    <td class="font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">Rp</td>
                    <td class="text-right font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_kewajiban | numFormat }}</td>
                    <td class="text-center font-700" style="border-top: 2px solid #002911; border-bottom: 2px solid #002911 !important;">{{ total_kewajiban != 0 ? 100 : 0 }}%</td>
                </tr>
            </table>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            model: {},
            year: this.$moment().format('Y'),
            total_aset: 0,
            total_aset_persentase: 0,
            total_kewajiban: 0,
            total_kewajiban_persentase: 0,
            lababersih: 0
        }
    },
    methods: {
        get()
        {
            let _ = this
            _.axios.get('/report/neraca?id_project=' + _.$route.params.id_project + '&year=' + _.year)
                .then( resp => {
                    _.model = resp.data.data
                    _.total_aset = 0
                    _.total_kewajiban = 0

                    for (let index = 0; index < _.model.aset.length; index++) {
                        _.total_aset += _.model.aset[index].total
                    }

                    for (let index = 0; index < _.model.kewajiban.length; index++) {
                        _.total_kewajiban += _.model.kewajiban[index].total
                    }

                    _.getLabaRugi()
                })
        },
        getLabaRugi()
        {
            let _ = this
            _.axios.get('/report/laba_rugi?id_project=' + _.$route.params.id_project + '&year=' + _.year)
                .then( resp => {
                    let labarugis = resp.data.data
                    let total_pendapatan = 0
                    let total_selain_pendapatan = 0

                    for (let index = 0; index < labarugis.pendapatan.length; index++) {
                        total_pendapatan += labarugis.pendapatan[index].total
                    }

                    for (let index = 0; index < labarugis.selain_pendapatan.length; index++) {
                        total_selain_pendapatan += labarugis.selain_pendapatan[index].total
                    }

                    _.lababersih = total_pendapatan - total_selain_pendapatan

                    _.total_kewajiban += _.lababersih 
                })
        }
    },
    mounted() {
        this.get()
    },
    watch: {
        year: function()
        {
            this.get();
        }
    }
}
</script>