<template>
    <div class="content-body">
        <div class="mb-2">
            <!-- <router-link to="/"> -->
                <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.25 15.5C1.25 15.5 0 15.5 0 14.25C0 13 1.25 9.25 7.5 9.25C13.75 9.25 15 13 15 14.25C15 15.5 13.75 15.5 13.75 15.5H1.25ZM7.5 8C8.49456 8 9.44839 7.60491 10.1517 6.90165C10.8549 6.19839 11.25 5.24456 11.25 4.25C11.25 3.25544 10.8549 2.30161 10.1517 1.59835C9.44839 0.895088 8.49456 0.5 7.5 0.5C6.50544 0.5 5.55161 0.895088 4.84835 1.59835C4.14509 2.30161 3.75 3.25544 3.75 4.25C3.75 5.24456 4.14509 6.19839 4.84835 6.90165C5.55161 7.60491 6.50544 8 7.5 8ZM16.25 9.875C16.25 9.70924 16.3158 9.55027 16.4331 9.43306C16.5503 9.31585 16.7092 9.25 16.875 9.25H19.375C19.5408 9.25 19.6997 9.31585 19.8169 9.43306C19.9342 9.55027 20 9.70924 20 9.875C20 10.0408 19.9342 10.1997 19.8169 10.3169C19.6997 10.4342 19.5408 10.5 19.375 10.5H16.875C16.7092 10.5 16.5503 10.4342 16.4331 10.3169C16.3158 10.1997 16.25 10.0408 16.25 9.875ZM13.75 6.125C13.75 5.95924 13.8158 5.80027 13.9331 5.68306C14.0503 5.56585 14.2092 5.5 14.375 5.5H19.375C19.5408 5.5 19.6997 5.56585 19.8169 5.68306C19.9342 5.80027 20 5.95924 20 6.125C20 6.29076 19.9342 6.44973 19.8169 6.56694C19.6997 6.68415 19.5408 6.75 19.375 6.75H14.375C14.2092 6.75 14.0503 6.68415 13.9331 6.56694C13.8158 6.44973 13.75 6.29076 13.75 6.125ZM13.75 2.375C13.75 2.20924 13.8158 2.05027 13.9331 1.93306C14.0503 1.81585 14.2092 1.75 14.375 1.75H19.375C19.5408 1.75 19.6997 1.81585 19.8169 1.93306C19.9342 2.05027 20 2.20924 20 2.375C20 2.54076 19.9342 2.69973 19.8169 2.81694C19.6997 2.93415 19.5408 3 19.375 3H14.375C14.2092 3 14.0503 2.93415 13.9331 2.81694C13.8158 2.69973 13.75 2.54076 13.75 2.375ZM16.25 13.625C16.25 13.4592 16.3158 13.3003 16.4331 13.1831C16.5503 13.0658 16.7092 13 16.875 13H19.375C19.5408 13 19.6997 13.0658 19.8169 13.1831C19.9342 13.3003 20 13.4592 20 13.625C20 13.7908 19.9342 13.9497 19.8169 14.0669C19.6997 14.1842 19.5408 14.25 19.375 14.25H16.875C16.7092 14.25 16.5503 14.1842 16.4331 14.0669C16.3158 13.9497 16.25 13.7908 16.25 13.625Z" fill="#3c8b43"/>
                </svg>
                <span class="menu-title align-self-center ml-1 text-primary"> Approval Perusahaan</span>
            <!-- </router-link> -->
        </div>
        
        <div class="card w-100 ">
            <div class="card-content">
                <div class="card-body ic-statistic">
                    <div class="col mb-1">
                        <div class="row">
                            <img src="/assets/images/logo-approval-pm-circle.png" alt="" class="img-fluid mr-1" width="42">
                            <p class="f-20 mb-0 align-self-center">Approval Perusahaan</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-xs-12 p-0 mb-1">
                        <div class="form-group input-group">
                            <span class="input-group-prepend">
                                <div class="input-group-text form-custom-bg border-left-0 border-right-0 border-top-0">
                                    <i class="fa fa-search"></i>
                                </div>
                            </span> 
                            <input type="text" class="form-control border-left-0 border-right-0 border-top-0" placeholder="Search by Name" v-model="search">
                        </div>
                        <p><span class="text-primary f-24 font-500">{{model.length}}</span> <span class="f-14 font-400" style="vertical-align:top;">Approval Perusahaan</span></p>
                    </div>
                    <div class="text-center" v-if="model.length == 0">
                        <p class="">Tidak ada data</p>
                    </div>
                    <div class="card w-100 mb-1" v-for="(data, index) in model" :key="index">
                        <div class="card-content">
                            <div class="card-body shadow">
                                <div class="col">
                                    <div class="row">
                                        <img src="/assets/img/icon/icon-company.png" alt="" class="img-fluid" style="width: 42px; height: 42px;">
                                        <div class="col mb-1">
                                            <p class="font-600 f-20 mb-0">{{ data.user.name }}</p>
                                            <div class="f-12 mb-0">
                                                <img src="/assets/img/icon/bi_check-circle-fill.png" class="img-fluid"> NIB : {{ data.no_nib }} 
                                                <div class="d-lg-none"></div>
                                                <img src="/assets/img/icon/bi_check-circle-fill.png" class="img-fluid"> NPWP : {{ data.direktur_npwp }}
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-xs-12 text-right p-0">
                                            <button class="btn btn-blue col-lg-4 col-xs-12" @click="approve(data)">
                                                Approve
                                            </button>
                                            &nbsp;
                                            <div class="d-lg-none mb-1"></div>
                                            <button class="btn btn-outline-danger col-lg-4 col-xs-12" @click="reject(data)"> 
                                                Reject
                                            </button>
                                            <div class="d-lg-none mb-1"></div>
                                        </div>
                                    </div>
                                </div>
                                <hr class="mt-0">
                                <div class="col mt-2">
                                    <div class="row">
                                        <div class="col-lg-4 col-xs-12">
                                            <div class="row text-center">
                                                <i class="fa fa-map-pin f-25 text-primary" style="width: 25px;"></i>
                                                <div class="col text-left">
                                                    <p class="f-14 text-secondary mb-0">Alamat</p>
                                                    <p class="f-14 text-justify" style="line-height: 15px;">{{ data.alamat }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xs-12">
                                            <div class="row text-center">
                                                <i class="fa fa-line-chart f-25 text-primary" style="width: 25px;"></i>
                                                <div class="col text-left">
                                                    <p class="f-14 text-secondary mb-0">No. Kemenkunham</p>
                                                    <p class="f-14 text-justify" style="line-height: 15px;">{{ data.no_sk }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xs-12">
                                            <div class="row text-center">
                                                <i class="fa fa-user-secret f-25 text-primary" style="width: 25px;"></i>
                                                <div class="col text-left">
                                                    <p class="f-14 text-secondary mb-0">Direktur</p>
                                                    <p class="f-14 text-justify" style="line-height: 15px;">{{ data.direktur_name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xs-12">
                                            <div class="row text-center">
                                                <i class="fa fa-user f-25 text-primary" style="width: 25px;"></i>
                                                <div class="col text-left">
                                                    <p class="f-14 text-secondary mb-0">Komisaris</p>
                                                    <p class="f-14 text-justify" style="line-height: 15px;">{{ data.komisaris_name }}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-2 col-xs-12">
                                            <!-- <div class="row text-center">
                                                <i class="fa fa-home f-25 text-primary" style="width: 25px;"></i>
                                                <div class="col text-left">
                                                    <p class="f-14 text-secondary mb-0">Project</p>
                                                    <p class="f-14 text-justify" style="line-height: 15px;">99</p>
                                                </div>
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>           
    </div>
</template>

<script>
export default {
	data() {
		return {
			model: [],
            model_backup: [],
            search: ''
		}
	},
    mounted() {
        let _ = this
        _.getList()
    },
    methods: {
        getList() {
            let _ = this
            _.axios.get('/company?status=pending')
                .then(resp=>{
                    _.model = resp.data.data
                    _.model_backup = resp.data.data
                })
                .catch(err=>{
                    console.log(err.response)
                })
        },
        approve(x) {
            let _ = this
            _.$swal.fire({
                title: 'Approval Perusahaan',
                html:'Apakah Anda yakin ingin <span class="text-primary">menyetujui</span> request Perusahaan <b>' + x.user.name + '</b>?',
                icon: 'question',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            status: 'approved'
                        }
                        _.axios.put('/user/approval/' + x.user.id, data)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Approved!',
                                        text: 'Successfully approve company',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        },
        reject(x) {
            let _ = this
            _.$swal.fire({
                title: 'Approval Perusahaan',
                html:'Apakah Anda yakin ingin <span class="text-danger">menolak</span> request Perusahaan <b>' + x.user.name + '</b>?',
                icon: 'question',
                input: 'textarea',
                inputPlaceholder: 'Tuliskan alasan',
                showCancelButton: true,
                cancelButtonText: 'Tidak',
                confirmButtonColor: '#0036A0',
                confirmButtonText: 'Ya',
                inputValidator: (value) => {
                    if (!value) {
                        return 'Beri alasan mengapa anda menolak request Perusahaan ' + x.user.name
                    }
                }
                }).then((result) => {
                    if (result.value) {
                        let data = {
                            status: 'rejected',
                            reject_reason: result.value
                        }
                        _.axios.put('/user/approval/' + x.user.id, data)
                            .then(resp => {
                                if (resp.status) {
                                    _.getList()
                                    _.$swal.fire({
                                        title: 'Rejected!',
                                        text: 'Successfully reject company',
                                        icon: 'success',
                                        confirmButtonColor: '#0036A0',
                                        confirmButtonText: 'OK'
                                    })
                                }else{
                                    console.log("Failed")
                                }
                            })
                            .catch(err=>{
                                console.log(err.response)
                            })                        
                    }
            })
        }
    },
    watch: {
        search: function() {
            let _ = this
            _.model = _.model_backup
            let searched = _.model.filter(function(data) {
                return data.user.name.toLowerCase().indexOf(_.search.toLowerCase()) > -1
            })
            _.model = searched
        }
    }
}
</script>
